<template>
  <div class="uk-container uk-form-width-large">
    <vue-headful
      description="token generation event"
      image="src/assets/logo.png"
      :title="`${isLite ? 'handleLite' : 'handle.fi'} | token generation event`"
    />

    <div class="hfi-tge">
      <div
        class="uk-flex uk-flex-between uk-flex-wrap uk-flex-middle uk-margin-remove"
      >
        <div class="uk-flex uk-flex-left uk-flex-middle uk-margin-remove">
          <img
            src="handle.fiDancingGorilla.gif"
            class="uk-position-relative uk-margin-small-right"
            style="margin-bottom: -14px !important; z-index: 1; height: 52px"
            alt="handle.fi dancing gorilla"
            width="40"
          />
          <h2 class="uk-h3 uk-margin-remove" style="line-height: 1">
            FOREX token generation event
          </h2>
        </div>

        <a
          class="uk-text-large hfi-dropdown-button uk-margin-xsmall-top"
          v-if="!tgeEnded"
          @click.prevent="openInstructions"
        >
          <i
            class="fal fa-info-square"
            uk-tooltip="title: open instructions; pos: left;"
          />
        </a>
      </div>

      <div
        :class="`uk-card uk-card-small uk-card-default uk-card-body uk-flex uk-flex-middle uk-margin-small-top hfi-tge-card-1 ${
          loading || !tgeStarted ? 'disabled-opacity' : ''
        }`"
      >
        <div class="uk-flex-1">
          <div class="uk-flex uk-flex-between uk-flex-wrap">
            <div
              class="cursor-pointer"
              uk-tooltip="title: status of the tge; pos: right"
            >
              <div class="uk-flex uk-flex-middle">
                status
                <i class="uk-margin-small-left fal fa-question-circle"></i>
                <div class="uk-margin-small-left hfi-danger">closed</div>
              </div>
            </div>

            <span>{{
              (!account
                ? "please connect wallet"
                : loading
                ? "loading..."
                : generationStartDate &&
                  generationStartDate.getTime() > Date.now()
                ? timeLeftToStartText
                : generationEndDate && generationEndDate.getTime() > Date.now()
                ? timeLeftToEndText
                : !tgeClaimable
                ? "tge ended, refund claimable"
                : "refund/FOREX claimable"
              ).toLowerCase()
            }}</span>
          </div>

          <div class="uk-flex uk-flex-between uk-flex-wrap">
            <span
              class="cursor-pointer"
              uk-tooltip="title: total amount of ETH currently contributed to the tge; pos: right"
            >
              total contributed <i class="fal fa-question-circle"></i>
            </span>
            <span> {{ formatEther(ethDeposited, 4) }} ETH </span>
          </div>

          <div class="uk-flex uk-flex-between uk-flex-wrap">
            <span
              class="cursor-pointer"
              uk-tooltip="title: current number of contributors to the tge; pos: right"
            >
              contributors <i class="fal fa-question-circle"></i>
            </span>
            <span> {{ depositorCount }}</span>
          </div>

          <div
            v-if="
              hardCap.gt(0) &&
              parseFloat(
                formatEther(
                  ethDeposited.mul(ethers.constants.WeiPerEther).div(hardCap)
                )
              ) > 1
            "
            class="uk-flex uk-flex-between uk-flex-wrap"
          >
            <span
              class="cursor-pointer"
              uk-tooltip="title: we have reached the max allocation target but the tge is still open; pos: right"
            >
              max allocation target
            </span>
            <span>reached</span>
          </div>

          <div class="uk-flex uk-flex-between uk-flex-wrap">
            <span
              class="cursor-pointer"
              uk-tooltip="title: current FOREX allocated as a percentage of maximum FOREX allocated to the tge at max token price; pos: right"
            >
              % max allocation target <i class="fal fa-question-circle"></i>
            </span>
            <span>
              {{
                hardCap.eq(0)
                  ? "0.00"
                  : parseFloat(
                      formatEther(
                        ethDeposited
                          .mul(ethers.constants.WeiPerEther)
                          .div(hardCap)
                          .mul(ethers.BigNumber.from(100))
                      )
                    ).toLocaleString(undefined, digits(2))
              }}%</span
            >
          </div>

          <div
            :class="`uk-flex uk-flex-between uk-flex-wrap hfi-yellow ${
              depositCap.gt(0) &&
              parseFloat(
                formatEther(
                  ethDeposited.mul(ethers.constants.WeiPerEther).div(depositCap)
                )
              ) >= 1
                ? 'hfi-danger'
                : ''
            }`"
          >
            <span
              class="cursor-pointer hfi-yellow"
              uk-tooltip="title: current ETH contributions as a percentage of maximum ETH contributions accepted during the tge; pos: right"
            >
              % max tge contribution
              <i class="fal fa-question-circle"></i>
            </span>
            <span>
              {{
                depositCap.eq(0)
                  ? "0.00"
                  : parseFloat(
                      formatEther(
                        ethDeposited
                          .mul(ethers.constants.WeiPerEther)
                          .div(depositCap)
                          .mul(ethers.BigNumber.from(100))
                      )
                    ).toLocaleString(undefined, digits(2))
              }}%</span
            >
          </div>

          <div
            v-if="!tgeClaimable"
            class="uk-flex uk-flex-between uk-flex-wrap"
          >
            <span
              class="cursor-pointer"
              uk-tooltip="title: FOREX price: shows the min price before the tge opens, the current price during the tge or the final price after it closes.  if it reaches the max price it will indicate such.; pos: right"
            >
              <label>{{
                !account
                  ? ""
                  : forexPrice.gt(0) && forexPrice.eq(maxTokenPrice)
                  ? ""
                  : tgeEnded
                  ? "final"
                  : tgeStarted
                  ? "current"
                  : "starting"
              }}</label>
              FOREX price
              {{
                forexPrice.gt(0) && forexPrice.eq(maxTokenPrice)
                  ? "reached max"
                  : ""
              }}
              <i class="fal fa-question-circle"></i>
            </span>
            <span
              class="cursor-pointer"
              :uk-tooltip="`title: ${(
                parseFloat(usdPrice) *
                ethers.utils.formatEther(
                  !tgeStarted ? minTokenPrice : forexPrice
                )
              ).toLocaleString(undefined, digits(2))} FOREX/USD`"
              ><i class="fal fa-usd-circle"></i>
              {{
                !tgeStarted
                  ? formatEther(minTokenPrice, 6)
                  : forexPrice.gt(0)
                  ? formatEther(forexPrice, 6)
                  : "tbc"
              }}
              FOREX/ETH</span
            >
          </div>

          <div class="uk-flex">
            <span
              class="cursor-pointer"
              :uk-tooltip="`title: click to view the tge contract on ${tgeExplorerMeta.name}; pos: right`"
            >
              <a
                class="hfi-link"
                :href="tgeExplorerMeta && tgeExplorerMeta.typeUrl"
                target="_blank"
                >view tge contract on {{ tgeExplorerMeta.name
                }}<i
                  class="uk-margin-small-left fal fa-external-link-square"
                ></i
              ></a>
            </span>
          </div>
        </div>
      </div>

      <div
        :class="`uk-card uk-card-small uk-card-default uk-card-body uk-flex uk-flex-middle uk-margin-small-top hfi-tge-card-2 ${
          loading || !tgeStarted ? 'disabled-opacity' : ''
        }`"
      >
        <div class="uk-flex-1">
          <p class="uk-h4 uk-margin-remove">your contribution</p>

          <div class="uk-flex uk-flex-between uk-flex-wrap">
            <span
              class="cursor-pointer"
              uk-tooltip="title: ETH balance in your connected wallet; pos: right"
            >
              wallet balance <i class="fal fa-question-circle"></i>
            </span>
            <span>{{ ethBalance ? formatEther(ethBalance, 4) : 0 }} ETH</span>
          </div>

          <div class="uk-flex uk-flex-between uk-flex-wrap">
            <span
              class="cursor-pointer"
              uk-tooltip="title: ETH amount originally contributed.  net contribution after refund shown under claim stats; pos: right"
            >
              contributed <i class="fal fa-question-circle"></i>
            </span>
            <span>{{ formatEther(userAmountDeposited, 4) }} ETH</span>
          </div>

          <div class="hfi-yellow uk-flex uk-flex-between uk-flex-wrap">
            <span
              class="cursor-pointer"
              uk-tooltip="title: max ETH accepted per contributor; pos: right"
            >
              user contribution cap <i class="fal fa-question-circle"></i>
            </span>
            <span>{{ formatEther(userCap, 4) }} ETH</span>
          </div>

          <div
            :class="`uk-flex uk-flex-between uk-flex-wrap ${
              userCap.sub(userAmountDeposited).eq(0) ? 'hfi-danger' : ''
            }`"
          >
            <span
              class="cursor-pointer"
              uk-tooltip="title: ETH amount remaining before user contribution cap reached; pos: right"
            >
              available to contribute <i class="fal fa-question-circle"></i>
            </span>
            <span
              >{{ formatEther(userCap.sub(userAmountDeposited), 4) }} ETH</span
            >
          </div>
        </div>
      </div>

      <button
        :class="`uk-button hfi-button uk-width-expand uk-flex uk-flex-middle uk-margin-small-top hfi-tnc-button ${
          loading ||
          !tgeStarted ||
          signedTermsAndConditions ||
          agreedToTncs ||
          tgeEnded
            ? ''
            : 'hfi-yellow'
        } ${loading || !tgeStarted ? 'disabled-opacity' : ''}`"
        :disabled="
          loading ||
          !tgeStarted ||
          tgeEnded ||
          signedTermsAndConditions ||
          agreedToTncs
        "
        @click="openTncModal"
      >
        <div class="uk-flex-1">
          <div
            :class="`uk-flex ${
              !loading &&
              tgeStarted &&
              !tgeEnded &&
              !signedTermsAndConditions &&
              !agreedToTncs
                ? 'uk-flex-center'
                : 'uk-flex-between'
            }`"
          >
            <span
              class="cursor-pointer"
              :uk-tooltip="`title: terms &amp; conditions ${
                loading || !tgeStarted
                  ? 'will need to be'
                  : signedTermsAndConditions || agreedToTncs
                  ? 'have been'
                  : 'have not been'
              } signed.${
                !loading &&
                tgeStarted &&
                !tgeEnded &&
                !signedTermsAndConditions &&
                !agreedToTncs
                  ? '  this is done before you make your first contribution.  you will only need to do this the once.'
                  : ''
              }; pos: bottom`"
            >
              {{
                !loading &&
                tgeStarted &&
                !tgeEnded &&
                !signedTermsAndConditions &&
                !agreedToTncs
                  ? "review and sign "
                  : ""
              }}
              terms &amp; conditions
              {{
                loading ||
                !tgeStarted ||
                tgeEnded ||
                signedTermsAndConditions ||
                agreedToTncs
                  ? "signed"
                  : ""
              }}
              <i class="fal fa-question-circle"></i
            ></span>
            <span
              v-if="
                loading ||
                !tgeStarted ||
                tgeEnded ||
                signedTermsAndConditions ||
                agreedToTncs
              "
              >{{
                !tgeStarted
                  ? "n/a"
                  : signedTermsAndConditions || agreedToTncs
                  ? "yes"
                  : "no"
              }}</span
            >
          </div>
        </div>
      </button>

      <form
        v-if="loading || !tgeStopped"
        novalidate
        autocomplete="off"
        :class="`uk-margin-small-top ${
          loading || !tgeStarted ? 'disabled-opacity' : ''
        }`"
      >
        <fieldset class="uk-fieldset uk-width-1-1 uk-position-relative">
          <label
            :class="`uk-form-label uk-flex uk-width-expand uk-flex-between ${
              canInteract ? '' : 'disabled-opacity'
            }`"
          >
            <span>ETH amount</span>
          </label>

          <div
            :class="`uk-form-controls uk-position-relative ${
              canInteract ? '' : 'disabled-opacity'
            }`"
          >
            <NumberInput
              id="amount"
              type="number"
              placeholder="amount to contribute"
              v-model="ethAmount"
              :disabled="!canInteract"
              :min="ethers.BigNumber.from(0)"
              :max="maxContribution"
              :alert="!sufficientBalance"
              :warning="canInteract && ethAmount.eq(0)"
            />

            <button
              class="uk-button hfi-button hfi-input-button"
              @click.prevent="setMaxAmount"
              :disabled="!canInteract"
            >
              max
            </button>
          </div>

          <div class="uk-margin-top">
            <button
              id="mainButton"
              :class="`uk-button uk-button-primary uk-width-expand hfi-button${
                !sufficientBalance
                  ? ' hfi-danger'
                  : mainButtonText === 'contribute'
                  ? ' hfi-contribute'
                  : ''
              }`"
              type="button"
              :disabled="!canContribute"
              @click="onContributeButtonPress"
              :uk-toggle="canInteract ? undefined : 'target: #tncs-modal'"
            >
              {{ mainButtonText }}
              <vue-loaders-ball-pulse
                style="margin-bottom: -6px"
                color="currentColor"
                scale="0.5"
                v-if="contributingOrClaiming"
              />
            </button>
          </div>
        </fieldset>
      </form>

      <div
        class="uk-card uk-card-small uk-card-default uk-card-body uk-flex uk-flex-middle uk-margin-small-top hfi-tge-card-4"
        v-if="!loading && tgeStopped"
      >
        <div class="uk-flex-1">
          <p class="uk-h4 uk-margin-remove">claim stats</p>

          <div class="uk-flex uk-flex-between uk-flex-wrap">
            <span
              class="cursor-pointer"
              uk-tooltip="title: your original ETH amount contributed before refund; pos: right"
            >
              ETH contributed <i class="fal fa-question-circle"></i
            ></span>
            <span>{{ formatEther(userAmountDeposited, 4) }} ETH</span>
          </div>

          <div
            v-if="userClaimableBalances.eth.gt(0)"
            class="uk-flex uk-flex-between uk-flex-wrap"
          >
            <span
              class="cursor-pointer"
              uk-tooltip="title: your ETH amount available to be refunded; pos: right"
            >
              ETH refundable <i class="fal fa-question-circle"></i>
            </span>
            <span>{{ formatEther(userEthRefundable, 4) }} ETH</span>
          </div>

          <div v-else class="uk-flex uk-flex-between uk-flex-wrap">
            <span
              class="cursor-pointer"
              uk-tooltip="title: your ETH amount already refunded; pos: right"
            >
              ETH refunded <i class="fal fa-question-circle"></i>
            </span>
            <span
              >{{
                formatEther(userEthRefundable.sub(userClaimableBalances.eth), 4)
              }}
              ETH</span
            >
          </div>

          <div class="uk-flex uk-flex-between uk-flex-wrap">
            <span
              class="cursor-pointer"
              uk-tooltip="title: your ETH amount contributed after refund; pos: right"
            >
              net ETH contribution <i class="fal fa-question-circle"></i>
            </span>
            <span>{{ formatEther(userNetEthContribution, 4) }} ETH</span>
          </div>

          <div class="uk-flex uk-flex-between uk-flex-wrap">
            <span>FOREX rate</span>
            <span>{{ formatEther(forexPrice, 6) }} FOREX/ETH</span>
          </div>

          <div class="uk-flex uk-flex-between uk-flex-wrap">
            <span>FOREX price (USD)</span>
            <span
              class="cursor-pointer"
              :uk-tooltip="`title: based upon a max FOREX/ETH price 0.00007 and ETH/USD 3000`"
            >
              <i class="fal fa-question-circle"></i>
              21c
            </span>
          </div>

          <div
            v-if="userClaimableBalances.forex.gt(0)"
            class="uk-flex uk-flex-between uk-flex-wrap"
          >
            <span>FOREX allocation</span>
            <span
              >{{ formatEther(userClaimableBalances.forex, 4) }}
              FOREX
            </span>
          </div>

          <div
            v-if="userClaimableBalances.forexReferred.gt(0)"
            class="uk-flex uk-flex-between uk-flex-wrap"
          >
            <span>FOREX allocation (referrals)</span>
            <span
              >{{
                formatEther(userClaimableBalances.forexReferred, 4)
              }}
              FOREX</span
            >
          </div>

          <div
            v-if="
              userAmountDeposited.gt(0) &&
              userClaimableBalances.forex.eq(0) &&
              userClaimableBalances.forexReferred.eq(0) &&
              forexAmount
                .mul(userDepositShare)
                .div(ethers.constants.WeiPerEther)
                .gt(0)
            "
            class="uk-flex uk-flex-between uk-flex-wrap"
          >
            <span>total FOREX allocation</span>
            <span
              >{{
                formatEther(
                  forexAmount
                    .mul(userDepositShare)
                    .div(ethers.constants.WeiPerEther),
                  4
                )
              }}
              FOREX
            </span>
          </div>

          <div
            v-if="
              userAmountDeposited.gt(0) &&
              userClaimableBalances.forex.eq(0) &&
              userClaimableBalances.forexReferred.eq(0) &&
              forexAmount
                .mul(userDepositShare)
                .div(ethers.constants.WeiPerEther)
                .gt(0)
            "
            class="uk-flex uk-flex-between uk-flex-wrap"
          >
            <span>FOREX claimed</span>
            <span
              >{{
                formatEther(
                  forexAmount
                    .mul(userDepositShare)
                    .div(ethers.constants.WeiPerEther),
                  4
                )
              }}
              FOREX</span
            >
          </div>
        </div>
      </div>

      <div
        v-if="!loading && tgeStopped && !tgeClaimable && !canClaimEth"
        class="uk-margin-medium-top"
      >
        <label> the tge is closed for contributions. </label>
        <label v-if="tgeEnded">FOREX</label>
        <label
          v-if="
            !tgeEnded && tgeStopped && !tgeClaimable && ethDeposited.gt(hardCap)
          "
          >ETH refund</label
        >
        <label> claims will be made available shortly. </label>
      </div>

      <form
        v-if="canClaimEth || (canClaimForex && tgeClaimable)"
        novalidate
        autocomplete="off"
        class="uk-margin-small-top"
      >
        <fieldset class="uk-fieldset uk-width-1-1 uk-position-relative">
          <div class="uk-margin-top">
            <button
              id="claimButton"
              :class="`uk-button uk-button-primary uk-width-expand hfi-button${
                !sufficientBalance ? ' hfi-danger' : ''
              }`"
              style="text-transform: none"
              type="button"
              :disabled="!canClaim || contributingOrClaiming"
              @click="onClaimButtonPress"
            >
              <label>claim </label>
              <label v-if="tgeClaimable && canClaimForex">
                {{
                  formatEther(
                    userClaimableBalances.forex.add(
                      userClaimableBalances.forexReferred
                    ),
                    4
                  )
                }}
                FOREX
              </label>
              <label v-if="canClaimEth && canClaimForex && tgeClaimable">
                and
              </label>
              <label v-if="canClaimEth">
                {{ formatEther(userClaimableBalances.eth, 4) }} ETH refund
              </label>
              <vue-loaders-ball-pulse
                style="margin-left: -10px; margin-bottom: -6px"
                color="currentColor"
                scale="0.5"
                v-if="contributingOrClaiming"
              />
            </button>
          </div>
        </fieldset>
      </form>
    </div>

    <div
      id="tncs-modal"
      class="uk-flex-top uk-margin-remove-top"
      uk-modal="bgClose: false; container: false;"
    >
      <div
        class="uk-modal-dialog uk-animation-slide-top-medium uk-modal-body uk-margin-auto-vertical uk-padding-small"
      >
        <h4
          class="uk-margin-remove-bottom"
          style="border-bottom: 1px solid; padding-bottom: 16px"
        >
          terms &amp; conditions
        </h4>
        <a @click.prevent="closeTncModal" class="uk-modal-close-default">
          <i class="fal fa-times"></i>
        </a>

        <vue-custom-scrollbar
          :settings="vueCustomScrollbarSettings"
          class="scroll-area uk-overflow-auto"
          style="max-height: 50vh; padding-top: 10px"
          @ps-scroll-down="handleScrollTC"
        >
          <div class="uk-panel">
            <Tncs />
          </div>
        </vue-custom-scrollbar>

        <div
          class="uk-margin-top uk-flex uk-flex-column uk-margin-remove-top"
          style="border-top: 1px solid; padding-top: 10px"
        >
          <label style="margin-bottom: 10px">
            by checking i hereby acknowledge i have read and comply with the
            Representations and Warranties in clauses 7(i) to 7(m)
            <input
              class="uk-checkbox"
              type="checkbox"
              :checked="specificClausesAcknowledged"
              @click="clickResidencyAcknowledge"
            />, and further
          </label>
          <button
            id="tncButton"
            :class="`uk-button uk-button-primary uk-width-expand hfi-button${
              sufficientBalance ? '' : ''
            }`"
            type="button"
            :disabled="!atBottomOfTncs || !specificClausesAcknowledged"
            @click="onAgreeToTC"
          >
            {{ tncButtonText }}
            <vue-loaders-ball-pulse
              style="margin-bottom: -6px"
              color="currentColor"
              scale="0.5"
              v-if="agreeingToTC"
            />
          </button>
        </div>
      </div>
    </div>

    <div id="instructions-modal" uk-modal="bgClose: true; escClose: true;">
      <TgeInstructions />
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import UIkit from "uikit";
import { store } from "@/store";
import { provider, signer } from "@/utils/wallet";
import NumberInput from "@/components/NumberInput";
import {
  showNotification,
  closeAllNotifications,
  getExplorerMeta,
} from "@/utils/utils";
import { addEventListener, removeEventListener } from "../utils/event";
import { getReferral } from "@/utils/url";
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import Tncs from "@/components/Tncs";
import TgeInstructions from "@/components/TgeInstructions";
import tge from "@/abi/tge";
import parseFxToken from "@/contracts/utils/parseFxToken";
import { format, addSeconds } from "date-fns";
import { getUSDPrice } from "../utils/prices";

export default {
  name: "Tge",
  components: {
    NumberInput,
    vueCustomScrollbar,
    Tncs,
    TgeInstructions,
  },
  data() {
    return {
      loading: true,
      dataRefreshIntervalId: null,
      provider: null,
      signer: null,
      referral: ethers.constants.AddressZero,
      ethers,
      ethBalance: ethers.BigNumber.from(0),
      ethAmount: ethers.BigNumber.from(0),
      contributingOrClaiming: false,
      agreeingToTC: false,
      atBottomOfTncs: false,
      vueCustomScrollbarSettings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: true,
        minScrollbarLength: 15,
      },
      walletUpdateEventId: null,
      oneEth: ethers.utils.parseEther("1"),
      tgeInstance: null,
      generationStartDate: null,
      generationDuration: null,
      generationEndDate: null,
      signedData: null,
      claimDate: 0,
      userClaimableBalances: {
        forex: ethers.BigNumber.from(0),
        forexReferred: ethers.BigNumber.from(0),
        eth: ethers.BigNumber.from(0),
      },
      forexPrice: ethers.BigNumber.from(0),
      timeLeftToStartCountdownIntervalId: null,
      timeLeftToEndCountdownIntervalId: null,
      overrideTgeAddress: null,
      userCap: ethers.BigNumber.from(0),
      userAmountDeposited: ethers.BigNumber.from(0),
      depositCap: ethers.BigNumber.from(0),
      forexAmount: ethers.BigNumber.from(0),
      agreedToTncs: false,
      signedTermsAndConditions: false,
      minTokenPrice: ethers.BigNumber.from(0),
      maxTokenPrice: ethers.BigNumber.from(0),
      termsAndConditions: null,
      depositorCount: ethers.BigNumber.from(0),
      ethDeposited: ethers.BigNumber.from(0),
      userDepositShare: ethers.BigNumber.from(0),
      timeLeftToStartText: "",
      timeLeftToEndText: "",
      formatEther: (value, digits = 2) =>
        parseFloat(ethers.utils.formatEther(value)).toLocaleString(
          undefined,
          this.digits(digits)
        ),
      format,
      specificClausesAcknowledged: false,
      usdPrice: 0,
      tgeExplorerMeta: "",
    };
  },
  computed: {
    network() {
      return store.state.network;
    },
    account() {
      return store.state.account;
    },
    sufficientBalance() {
      if (this.loading || !this.account || !this.ethBalance) {
        return true;
      }
      return this.ethAmount.lte(this.maxContribution);
    },
    aboveCap() {
      if (this.loading || !this.account || !this.ethBalance) {
        return false;
      }
      return this.ethAmount.gt(this.amountBelowCap);
    },
    mainButtonText() {
      if (!this.account) return "please connect wallet";
      if (this.loading || !this.tgeInstance) return "loading...";
      if (this.tgeEnded) return "tge closed";
      if (this.generationStartDate > new Date()) {
        this.timeLeftToStartText = this.getTimeToStartText();
        return this.timeLeftToStartText;
      }
      if (!this.signedTermsAndConditions && !this.agreedToTncs)
        return "review and sign terms & conditions";
      if (this.ethAmount.eq(0)) return "enter amount";
      if (!this.sufficientBalance) return "insufficient balance";
      if (this.aboveCap) return "exceeds user cap";
      if (this.contributingOrClaiming) return "";
      return "contribute";
    },
    tncButtonText() {
      return !this.atBottomOfTncs
        ? "please read to the bottom"
        : !this.specificClausesAcknowledged
        ? "please check to acknowledge clauses 7(i) to 7(m)"
        : this.agreeingToTC
        ? ""
        : "i agree to all terms & conditions";
    },
    canInteract() {
      return (
        !this.loading &&
        this.account &&
        this.tgeStarted &&
        this.maxContribution.gt(0) &&
        this.depositCap.gt(0) &&
        this.ethDeposited
          .mul(ethers.constants.WeiPerEther)
          .div(this.depositCap)
          .lt(ethers.constants.WeiPerEther) &&
        (this.signedTermsAndConditions || this.agreedToTncs) &&
        !this.contributingOrClaiming
      );
    },
    canContribute() {
      return (
        this.tgeStarted &&
        !this.tgeEnded &&
        !this.aboveCap &&
        this.account &&
        this.ethAmount.gt(0) &&
        this.sufficientBalance &&
        (this.signedTermsAndConditions || this.agreedToTncs) &&
        !this.contributingOrClaiming
      );
    },
    canClaim() {
      return this.account && (this.canClaimEth || this.canClaimForex);
    },
    maxContribution() {
      const value = this.ethBalance
        ? this.ethBalance.lt(this.amountBelowCap)
          ? this.ethBalance
          : this.amountBelowCap
        : ethers.BigNumber.from(0);
      return value.gt(0) ? value : ethers.constants.Zero;
    },
    amountBelowCap() {
      return this.userCap.sub(this.userAmountDeposited);
    },
    hardCap() {
      return this.maxTokenPrice
        .mul(this.forexAmount)
        .div(ethers.constants.WeiPerEther);
    },
    tgeStarted() {
      return this.generationStartDate && this.generationStartDate <= new Date();
    },
    tgeEnded() {
      return this.generationEndDate && this.generationEndDate < new Date();
    },
    /** Not ended yet but deposit cap has been met. */
    tgeStopped() {
      return this.tgeEnded || this.ethDeposited.gte(this.depositCap);
    },
    tgeClaimable() {
      return this.claimDate > 0 && Date.now() > this.claimDate;
    },
    userEthRefundable() {
      if (this.ethDeposited.lte(this.hardCap)) return ethers.constants.Zero;
      return this.ethDeposited
        .sub(this.hardCap)
        .mul(this.userDepositShare)
        .div(ethers.constants.WeiPerEther);
    },
    userNetEthContribution() {
      if (this.ethDeposited.lte(this.hardCap)) return this.userAmountDeposited;
      const ethRefundable = this.ethDeposited
        .sub(this.hardCap)
        .mul(this.userDepositShare)
        .div(ethers.constants.WeiPerEther);
      return this.userAmountDeposited.sub(ethRefundable);
    },
    canClaimEth() {
      return this.userClaimableBalances.eth.gt(0) && this.tgeEnded;
    },
    canClaimForex() {
      return (
        (this.tgeClaimable && this.userClaimableBalances.forex.gt(0)) ||
        this.userClaimableBalances.forexReferred.gt(0)
      );
    },
    networks() {
      return this.$route.meta.networks;
    },
    isLite() {
      return store.state.isLite;
    },
  },

  watch: {
    async network() {
      await this.resetState();

      if (
        this.account &&
        this.networks.includes(this.network) &&
        !this.tgeEnded
      )
        this.openInstructions();
    },
    async account() {
      await this.resetState();

      if (
        this.account &&
        this.networks.includes(this.network) &&
        !this.tgeEnded
      )
        this.openInstructions();
    },
    async tgeClaimable() {
      await this.resetState();
    },
  },

  async mounted() {
    await this.initialiseState();

    window.setTgeContract = async (address) => {
      this.overrideTgeAddress = address;
      localStorage.removeItem("signedData_" + this.account);
      await this.resetState();
    };
  },

  beforeDestroy() {
    removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
  },

  methods: {
    async initialiseState() {
      removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
      this.walletUpdateEventId = addEventListener(
        Event.WalletUpdate,
        this.resetState.bind(this)
      );

      await this.resetState();

      if (
        this.account &&
        this.networks.includes(this.network) &&
        !this.tgeEnded
      )
        this.openInstructions();
    },

    async resetState() {
      closeAllNotifications();
      this.loading = true;
      this.provider = null;
      this.referral = ethers.constants.AddressZero;
      this.ethBalance = undefined;
      this.ethAmount = ethers.BigNumber.from(0);
      this.signedTermsAndConditions = false;
      this.agreedToTncs = false;
      this.agreeingToTC = false;
      this.contributingOrClaiming = false;
      this.atBottomOfTncs = false;
      this.userAmountDeposited = ethers.BigNumber.from(0);

      const referral = getReferral();
      if (referral) {
        console.log(`using referral "${this.referral}" for TGE`);
        this.referral = referral;
      }

      if (this.account) {
        this.provider = provider;
        this.signer = signer;
        await this.loadData(this.overrideTgeAddress);
      }

      if (!this.tgeStarted && !this.timeLeftToStartCountdownIntervalId)
        this.timeLeftToStartCountdownIntervalId = setInterval(
          () => this.timeLeftToStartCountdownIteration(),
          100
        );

      if (
        this.tgeStarted &&
        !this.tgeEnded &&
        !this.timeLeftToEndCountdownIntervalId
      )
        this.timeLeftToEndCountdownIntervalId = setInterval(
          () => this.timeLeftToEndCountdownIteration(),
          100
        );

      if (!this.dataRefreshIntervalId)
        this.dataRefreshIntervalId = setInterval(
          () => this.dataRefreshIteration(),
          5000
        );
      this.loading = false;
    },

    async loadData(address) {
      this.tgeInstance = new ethers.Contract(
        address || parseFxToken("tge"),
        tge,
        this.provider
      );
      await this.getTgeParameters();
    },

    openInstructions() {
      UIkit.modal("#instructions-modal").show();
    },

    async getEthBalance() {
      if (signer) {
        this.ethBalance = await signer.getBalance();
      }
    },

    onContributeButtonPress() {
      if (this.signedTermsAndConditions || this.agreedToTncs) {
        this.onContribute();
      } else {
        this.openTncModal();
      }
    },

    async onClaimButtonPress() {
      this.contributingOrClaiming = true;
      showNotification(
        "warning",
        `please follow wallet instructions to approve the ${
          this.canClaimEth && this.canClaimForex && this.tgeClaimable
            ? "ETH refund & FOREX claim"
            : this.canClaimEth
            ? "ETH refund"
            : this.canClaimForex && this.tgeClaimable
            ? "FOREX claim"
            : ""
        }`,
        undefined,
        0
      );

      const signer = this.tgeInstance.connect(this.signer);
      try {
        const tx = await signer.claim();

        closeAllNotifications();
        showNotification(
          "success",
          "waiting for confirmation...",
          undefined,
          0
        );
        await tx.wait(1);

        const explorerMeta = await getExplorerMeta(tx.hash, "tx");

        closeAllNotifications();
        showNotification(
          "success",
          `successfully claimed ${
            this.canClaimEth && this.canClaimForex && this.tgeClaimable
              ? "ETH refund & FOREX"
              : this.canClaimEth
              ? "ETH refund"
              : this.canClaimForex && this.tgeClaimable
              ? "FOREX"
              : ""
          }! thank you #trooper! ${explorerMeta.typeMessageWithLink}`,
          undefined,
          0
        );

        this.resetState();
      } catch (e) {
        console.log("Claim error", e);

        closeAllNotifications();
        showNotification(
          "error",
          e.message
            ? e.message.toLowerCase()
            : "error claiming, please try again.",
          undefined,
          0
        );
      }
      this.contributingOrClaiming = false;
    },

    async onContribute() {
      this.contributingOrClaiming = true;
      showNotification(
        "warning",
        "please follow wallet instructions to approve the contribution",
        undefined,
        0
      );

      const signer = this.tgeInstance.connect(this.signer);

      try {
        const isContractSigned = await this.tgeInstance.signedTermsAndConditions(
          this.account
        );
        const signature = isContractSigned ? new Uint8Array() : this.signedData;

        const tx = await signer.deposit(this.referral, signature, {
          value: this.ethAmount,
        });

        closeAllNotifications();
        showNotification(
          "success",
          "waiting for confirmation...",
          undefined,
          0
        );
        await tx.wait(1);

        const explorerMeta = await getExplorerMeta(tx.hash, "tx");

        closeAllNotifications();
        showNotification(
          "success",
          `successfully contributed ${this.formatEther(
            this.ethAmount,
            4
          )} ETH to the handle tge! thank you ${
            this.userAmountDeposited.eq(0)
              ? "and welcome"
              : "for your further contribution"
          } #trooper! ${explorerMeta.typeMessageWithLink}`,
          undefined,
          0
        );

        this.resetState();
      } catch (e) {
        console.log("Contribute error", e);

        closeAllNotifications();
        showNotification(
          "error",
          e.message
            ? e.message.toLowerCase()
            : "error contributing, please try again.",
          undefined,
          0
        );
      }

      this.contributingOrClaiming = false;
    },

    async onAgreeToTC() {
      this.agreeingToTC = true;
      showNotification(
        "warning",
        "please sign terms & conditions in your wallet",
        undefined,
        0
      );

      try {
        const message = this.termsAndConditions;
        this.signedData = ethers.utils.arrayify(
          await this.signer.signMessage(message)
        );
        const signedArray = Array.from
          ? Array.from(this.signedData)
          : [].map.call(this.signedData, (v) => v);

        this.agreedToTncs = true;
        localStorage.setItem(
          "signedData_" + this.account,
          JSON.stringify(signedArray)
        );

        closeAllNotifications();
        showNotification(
          "success",
          "terms & conditions signed. agreement is submitted during contribution",
          undefined,
          0
        );
      } catch (e) {
        closeAllNotifications();
        showNotification(
          "error",
          e.message
            ? e.message.toLowerCase()
            : "error signing terms & conditions, please try again",
          undefined,
          0
        );
      }

      this.agreeingToTC = false;
      this.closeTncModal();
    },

    openTncModal() {
      UIkit.modal("#tncs-modal").show();
    },

    closeTncModal() {
      UIkit.modal("#tncs-modal").hide();
    },

    handleScrollTC(el) {
      if (!(el.srcElement.scrollHeight > 0)) return;
      const percentToBottom =
        (el.srcElement.offsetHeight + el.srcElement.scrollTop) /
        el.srcElement.scrollHeight;
      this.atBottomOfTncs = this.atBottomOfTncs || percentToBottom >= 0.999;
    },

    setMaxAmount() {
      this.ethAmount = this.maxContribution;
    },

    async getTermsAndConditions() {
      this.termsAndConditions = await this.tgeInstance.termsAndConditions();
    },

    async getSignedTermsAndConditions() {
      if (this.account)
        this.signedTermsAndConditions = await this.tgeInstance.signedTermsAndConditions(
          this.account
        );
      else this.signedTermsAndConditions = false;
    },

    async getClaimDate() {
      this.claimDate = await this.tgeInstance.claimDate();
    },

    async getEthDeposited() {
      this.ethDeposited = await this.tgeInstance.ethDeposited();
    },

    async getForexPrice() {
      this.forexPrice = await this.tgeInstance.forexPrice();
    },

    async getUserDepositShare() {
      if (this.account)
        this.userDepositShare = await this.tgeInstance.shareOf(this.account);
      else this.userDepositShare = ethers.BigNumber.from(0);
    },

    async getUserAmountDeposited() {
      if (this.account)
        this.userAmountDeposited = await this.tgeInstance.getDeposit(
          this.account
        );
      else this.userAmountDeposited = ethers.BigNumber.from(0);
    },

    async getUserClaimableBalances() {
      if (this.account) {
        if (!this.tgeStopped || this.tgeEnded || this.tgeClaimable) {
          this.userClaimableBalances = await this.tgeInstance.balanceOf(
            this.account
          );
        } else {
          this.userClaimableBalances = {
            eth: this.ethDeposited.lte(this.hardCap)
              ? ethers.constants.Zero
              : this.ethDeposited
                  .sub(this.hardCap)
                  .mul(this.userDepositShare)
                  .div(ethers.constants.WeiPerEther),
            forexReferred: ethers.constants.Zero, // referrals disabled
            forex: this.forexAmount
              .mul(this.userDepositShare)
              .div(ethers.constants.WeiPerEther),
          };
        }
      } else
        this.userClaimableBalances = {
          forex: ethers.BigNumber.from(0),
          forexReferred: ethers.BigNumber.from(0),
          eth: ethers.BigNumber.from(0),
        };
    },

    async getGenerationStartDate() {
      const generationStartDate = await this.tgeInstance.generationStartDate();
      this.generationStartDate = new Date(
        generationStartDate.toNumber() * 1000
      );
    },

    async getUserCap() {
      this.userCap = await this.tgeInstance.userCap();
    },

    async getGenerationDuration() {
      const generationDuration = await this.tgeInstance.generationDuration();
      this.generationDuration = generationDuration.toNumber();
    },

    async getDepositCap() {
      this.depositCap = await this.tgeInstance.depositCap();
    },

    async getDepositorCount() {
      this.depositorCount = await this.tgeInstance.depositorCount();
    },

    async getMinTokenPrice() {
      this.minTokenPrice = await this.tgeInstance.minTokenPrice();
    },

    async getMaxTokenPrice() {
      this.maxTokenPrice = await this.tgeInstance.maxTokenPrice();
    },

    async getForexAmount() {
      this.forexAmount = await this.tgeInstance.forexAmount();
    },

    async getUsdPrice() {
      this.usdPrice = await getUSDPrice("ETH");
    },

    async getExplorerMeta() {
      this.tgeExplorerMeta = await getExplorerMeta(
        this.overrideTgeAddress || parseFxToken("tge")
      );
    },

    async getTgeParameters() {
      await Promise.all([
        this.getEthBalance(),
        this.getGenerationStartDate(),
        this.getGenerationDuration(),
        this.getTermsAndConditions(),
        this.getSignedTermsAndConditions(),
        this.getClaimDate(),
        this.getEthDeposited(),
        this.getForexPrice(),
        this.getUserDepositShare(),
        this.getUserAmountDeposited(),
        this.getUserClaimableBalances(),
        this.getUserCap(),
        this.getDepositCap(),
        this.getDepositorCount(),
        this.getMinTokenPrice(),
        this.getMaxTokenPrice(),
        this.getForexAmount(),
        this.getUsdPrice(),
        this.getExplorerMeta(),
      ]);

      this.generationEndDate = addSeconds(
        this.generationStartDate,
        this.generationDuration
      );

      if (this.account) {
        if (localStorage.getItem("signedData_" + this.account)) {
          this.agreedToTncs = true;
          this.signedData = new Uint8Array(
            JSON.parse(localStorage.getItem("signedData_" + this.account))
          );
        }
      }
    },

    timeLeftToStartCountdownIteration() {
      if (!this.timeLeftToStartCountdownIntervalId || !this.generationStartDate)
        return;
      this.timeLeftToStartText = this.getTimeToStartText();
      if (Date.now() < this.generationStartDate.getTime()) return;
      clearInterval(this.timeLeftToStartCountdownIntervalId);
      this.resetState();
    },

    getTimeToStartText() {
      const timeLeft = this.generationStartDate.getTime() - Date.now();
      // More than one day left.
      if (timeLeft > 24 * 60 * 60 * 1000)
        return `tge starts ${format(
          this.generationStartDate,
          "ddd DD MMM, YYYY"
        )}`;
      // Less than one day left -- display hour countdown.
      if (timeLeft > 60 * 60 * 1000) {
        const hoursLeft = timeLeft / (60 * 60 * 1000);
        const minutesLeft = Math.floor((timeLeft / (60 * 1000)) % 60);
        return `tge starts in ${Math.floor(hoursLeft)}h ${minutesLeft}min`;
      }
      // Less than one hour left -- display minute countdown.
      if (timeLeft > 60 * 1000) {
        const minutesLeft = Math.floor(timeLeft / (60 * 1000));
        const secondsLeft = Math.floor((timeLeft / 1000) % 60);
        return `tge starts in ${minutesLeft}min ${secondsLeft}s`;
      }
      // Less than one minute left -- display second countdown.
      return `tge starts in ${(timeLeft / 1000).toFixed(1)}s`;
    },

    timeLeftToEndCountdownIteration() {
      if (!this.timeLeftToEndCountdownIntervalId || !this.generationEndDate)
        return;
      this.timeLeftToEndText = this.getTimeToEndText();
      if (Date.now() < this.generationEndDate.getTime()) return;
      clearInterval(this.timeLeftToEndCountdownIntervalId);
      this.resetState();
    },

    getTimeToEndText() {
      if (!this.tgeStarted) return "not started yet";
      if (this.tgeEnded) return "tge closed";
      if (!this.generationEndDate) return "loading...";

      const timeLeft = this.generationEndDate.getTime() - Date.now();
      if (timeLeft < 0) return "closed";
      const endPrefix = "claim refund in";
      // Less than one day left -- display hour countdown.
      if (timeLeft > 60 * 60 * 1000) {
        const hoursLeft = timeLeft / (60 * 60 * 1000);
        const minutesLeft = Math.floor((timeLeft / (60 * 1000)) % 60);
        return `${endPrefix} ${Math.floor(hoursLeft)}h ${minutesLeft}min`;
      }
      // Less than one hour left -- display minute countdown.
      if (timeLeft > 60 * 1000) {
        const minutesLeft = Math.floor(timeLeft / (60 * 1000));
        const secondsLeft = Math.floor((timeLeft / 1000) % 60);
        return `${endPrefix} ${minutesLeft}min ${secondsLeft}s`;
      }
      // Less than one minute left -- display second countdown.
      return `${endPrefix} ${(timeLeft / 1000).toFixed(1)}s`;
    },

    dataRefreshIteration() {
      if (!this.dataRefreshIntervalId) return;
      this.getTgeParameters();
    },

    clickResidencyAcknowledge() {
      this.specificClausesAcknowledged = !this.specificClausesAcknowledged;
    },

    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
  },
};
</script>

<style lang="scss">
@use "../assets/styles/handle.fi" as handle;

.hfi-tge .uk-card {
  padding-top: 10px;
  padding-bottom: 10px;
}
#instructions-modal .uk-card {
  padding-top: 10px;
  padding-bottom: 10px;
}

.hfi-card-number {
  width: 35px;
  align-self: flex-start;
}

.hfi-input-button {
  right: 24px;
  &.justify-between {
    justify-content: space-between !important;
  }
}

#tncs-modal .ps--active-y > .ps__rail-y {
  width: 16px;
  border-left: 2px solid #a9e2b0;
  border-right: 2px solid #a9e2b0;
}
#tncs-modal .ps .ps__rail-y:hover,
#tncs-modal .ps .ps__rail-y:focus,
#tncs-modal .ps .ps__rail-y.ps--clicking {
  width: 16px;
}

@keyframes pulse-tge {
  0% {
    transform-origin: left center;
    transform: scale(0.5);
  }
  50% {
    transform-origin: left center;
    transform: scale(1.2);
  }
  70% {
    transform-origin: left center;
    transform: scale(0.75);
  }
  100% {
    transform-origin: left center;
    transform: scale(0.5);
  }
}

.pulse-tge {
  animation: pulse-tge 0.9s ease-out infinite;
}
</style>
