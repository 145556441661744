<template>
  <div>
    <p
      style="
        line-height: 1.295;
        text-align: center;
        margin-top: 0pt;
        margin-bottom: 8pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >$FOREX TOKEN - TERMS AND CONDITIONS OF TOKEN SALE AND USAGE</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 8pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >PLEASE READ THESE TERMS OF TOKEN SALE AND USAGE CAREFULLY. IF YOU DO
        NOT AGREE TO THESE TERMS (AS DEFINED HEREIN), DO NOT PURCHASE (WHETHER
        THROUGH AN INTERMEDIARY OR OTHERWISE) OR CONTINUE TO HOLD OR USE $FOREX
        (AS DEFINED BELOW). THESE TERMS DO NOT CONSTITUTE A PROSPECTUS OR
        OFFERING DOCUMENT, AND ARE NOT AN OFFER TO SELL, NOR THE SOLICITATION OF
        AN OFFER TO BUY ANY INVESTMENT OR FINANCIAL INSTRUMENT IN ANY
        JURISDICTION.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 8pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >BY MAKING A CONTRIBUTION TO THE SELLER (AS DEFINED BELOW) OR ANY
        INTERMEDIARY FOR THE PURCHASE OF $FOREX, OR BY CONTINUING TO HOLD OR USE
        $FOREX WHICH YOU MAY HAVE OBTAINED BY ANY OTHER MEANS, YOU WILL BE BOUND
        BY THESE TERMS AND ALL TERMS INCORPORATED HEREIN BY REFERENCE. BY
        ACCEPTING THESE TERMS, YOU WILL BE ENTERING INTO A BINDING AGREEMENT
        WITH THE SELLER, WHICH TERMS CONTAIN PROVISIONS WHICH AFFECT YOUR LEGAL
        RIGHTS. THE PURCHASE, HOLDING AND USAGE OF DIGITAL TOKENS IS SUBJECT TO
        A NUMBER OF RISKS (INCLUDING FINANCIAL RISK), SOME OF WHICH THE SELLER
        HAS SET OUT IN THESE TERMS. IF YOU ARE IN ANY DOUBT AS TO THE
        SUITABILITY OR OTHERWISE OF PURCHASING, HOLDING OR USAGE OF THE DIGITAL
        TOKENS REFERRED TO IN THESE TERMS, YOU SHOULD SEEK APPROPRIATE
        PROFESSIONAL ADVICE.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 8pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >NOTHING IN THESE TERMS CONSTITUTES LEGAL, FINANCIAL, BUSINESS OR TAX
        ADVICE AND YOU SHOULD CONSULT YOUR OWN LEGAL, FINANCIAL, TAX OR OTHER
        PROFESSIONAL ADVISER BEFORE ENGAGING IN ANY ACTIVITY IN CONNECTION
        HEREWITH.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 8pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Your purchase, whether through an intermediary or otherwise, of $FOREX
        tokens (</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >$FOREX</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >) from&nbsp;</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >COVENANT GLOBAL INC.</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >, a company incorporated or to be incorporated in the Republic of
        Panama (the&nbsp;</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Seller</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >, or&nbsp;</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >us</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >), as well as continued holding and/or usage of $FOREX is subject to
        these Terms and Conditions (the&nbsp;</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Terms</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >). Each of you and the Seller is a &ldquo;Party,&rdquo; and together
        the &ldquo;Parties.&rdquo; Please read the below terms and conditions
        carefully before registering, accessing, browsing, downloading and/or
        using the website at https://handle.fi/ (the&nbsp;</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Website</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >). By accessing or using the Website, purchasing $FOREX (whether
        through an intermediary or otherwise), or continuing to hold or use
        $FOREX, you agree to be bound by these Terms (and all terms incorporated
        by reference).</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 8pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Before agreeing to the Terms, you must read this document in full. If
        at any time you do not agree to these terms and conditions or do not
        wish to be bound by these terms and conditions, you may not access or
        use the Website, and shall not be entitled to purchase $FOREX (whether
        through an intermediary or otherwise) or continue to hold or use $FOREX.
        The Seller shall be under no obligation to maintain a copy of these
        Terms on the Website after the sale of $FOREX, and you are advised to
        print or download and keep a copy of these Terms for your future
        reference (if required).</span
      >
    </p>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px">
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >PURPOSE AND USAGE OF TOKENS</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >$FOREX is a cryptographic utility token. The purpose of $FOREX is to
        facilitate the participation in the &quot;Handle.fi&quot; protocol
        (the&nbsp;</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Handle.fi protocol</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >) which, when fully developed, is envisaged to be a decentralised
        finance protocol enabling the creation and exchange of secondary
        multi-currency stablecoins (fx-Token) and other tokens. The Handle.fi
        protocol is not, and will in no case be, an enterprise, corporation,
        partnership or other entity or body corporate established under the laws
        of any jurisdiction, but a computerised consensus protocol based on
        which a public transaction ledger for transactions thereon may be
        generated.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >$FOREX is designed to be the governance token and incentives for
        contributions to and/or maintenance of the Handle.fi protocol. Given
        that additional $FOREX will be awarded to a user based only on its
        actual usage, activity and contribution on the Handle.fi protocol, users
        and/or holders of $FOREX which did not actively participate in the
        Handle.fi protocol will not receive any $FOREX incentives.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The precise scope of the Handle.fi protocol will be developed further
        and will be announced once finalised. The following features are planned
        for $FOREX: (a) $FOREX, along with other digital assets such as DAI and
        ETH may be staked as collateral, for users to mint secondary fx-Tokens
        (and possibly other handle.fi tokens in future), each secured by that
        collateral provided. To incentivise users to provide these collateral,
        stakers would be rewarded with additional $FOREX tokens based on their
        work performed, (b) in order for the Handle.fi protocol to function
        properly, users would need to be incentivised to play the role of
        liquidity providers and stake their digital assets into the handle.fi or
        other AMM exchange market making pools for secondary fx-Tokens. As
        compensation for opportunity costs as well as impermanent losses, these
        liquidity providers which help to promote adoption of the Handle.fi
        protocol by staking or including assets to liquidity pools would be
        rewarded with $FOREX tokens (i.e. &quot;liquidity mining&quot; on the
        Handle.fi protocol), according to each user&apos;s relative contribution
        after various adjustment and correction parameters, (c) $FOREX would
        allow holders to propose and vote on governance proposals to determine
        features and/or parameters of the Handle.fi protocol, with voting weight
        calculated in proportion to their token holdings (for the avoidance of
        doubt, the right to vote is restricted solely to voting on features of
        the Handle.fi protocol; the right to vote does not entitle $FOREX
        holders to vote on the operation and management of the Company, its
        affiliates, or their assets, and does not constitute any equity interest
        in any of these entities). Users must hold $FOREX in order to be
        entitled to participate in protocol governance voting, and (d)</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        ><span class="Apple-tab-span" style="white-space: pre"
          >&nbsp; &nbsp;&nbsp;</span
        ></span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >$FOREX may, at a later stage of development, be used for payment of
        various fees on the protocol, for example the transaction fee,
        conversion fee or redemption fee, and holding $FOREX may entitle holders
        to receive discounts on the fees payable. For the avoidance of doubt,
        the community does not have legal control over any matters relating to
        the Seller (or any of its affiliates) or any of its assets, or any of
        its affiliated companies (including without limitation the selection of
        governing board of the relevant company, over corporate matters,
        development direction, specific projects, or deployment of that
        company&apos;s assets, which shall be the final responsibility of the
        governing board of the relevant company).</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Immediately after the token sale phase, $FOREX would have limited
        functionality. As development of the Handle.fi protocol progresses
        further, the various features of $FOREX would be implemented
        incrementally and become available over time.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >$FOREX is an integral and indispensable part of the Handle.fi protocol
        because in the absence of $FOREX, there would be no common unit of
        exchange for goods and services or economic incentive to motivate users
        to contribute, thus rendering the ecosystem on the Handle.fi protocol
        unsustainable. The ownership of $FOREX carries no rights, express or
        implied, in the Seller, its related entities or its affiliates (each,
        a&nbsp;</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Group Entity</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >) other than the right to use $FOREX as a means to enable usage of and
        interaction with the Handle.fi protocol, upon the successful development
        and deployment of the Handle.fi protocol. $FOREX is sold as a usable
        virtual good, and does not have any functionality or utility outside the
        ecosystem on the Handle.fi protocol &ndash; accordingly it is not
        necessarily merchantable and does not necessarily have any other use or
        value. The ecosystem on the Handle.fi protocol is structured as a
        &quot;closed system&quot; insofar as the usage of $FOREX is concerned.
        Further, $FOREX is not a consumer product and to the maximum extent
        permitted by law, its holders accept explicitly and agree that they are
        not covered by the consumer protection regulation of any
        jurisdiction.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >You understand and accept that $FOREX:</span
      >
    </p>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px">
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >may only be utilised on the Handle.fi protocol, is non-refundable
            and cannot be exchanged for cash (or its equivalent value in any
            other virtual currency) or any payment obligation by any Group
            Entity;</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="2"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >does not represent or confer on you any ownership right,
            shareholding, participation, right, title, or interest of any form
            with respect to any Group Entity or any other company, enterprise or
            undertaking, or any of their revenues or assets, including without
            limitation any right to receive future revenue, dividends, shares,
            ownership right or stake, share or security, any voting,
            distribution, redemption, liquidation, proprietary (including all
            forms of intellectual property), right to receive accounts,
            financial statements or other financial data, the right to
            requisition or participate in shareholder meetings, the right to
            nominate a director or other financial or legal rights or equivalent
            rights, or intellectual property rights or any other form of
            participation in or relating to the Handle.fi protocol, any Group
            Entity and/or any service provider of any Group Entity;</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="3"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >is not intended to be a representation of currency or money
            (whether fiat or virtual or any form of electronic money), security,
            commodity, bond, debt instrument, unit in a collective investment
            scheme or any other kind of financial instrument or
            investment;</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="4"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >is not intended to represent any rights under a contract for
            differences or under any other contract the purpose or pretended
            purpose of which is to secure a profit or avoid a loss;</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="5"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >is not a loan to any Group Entity and is not intended to represent
            a debt owed by any Group Entity, and there shall be no expectation
            of profit or interest income arising in connection therewith;</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="6"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >does not provide you with any ownership or other interest in any
            Group Entity;</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="7"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >is not any form of financial derivative;</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="8"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >is not any form of commercial paper or negotiable instrument;</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="9"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >will not entitle token holders to any promise of fees, dividends,
            revenue, profits or investment returns, nor should there be any such
            expectation;</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="10"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >is not any note, debenture, warrant or other certificate that
            entitles the holder to any interest, dividend or any kind of return
            from any Group Entity or any person;</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="11"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >is not any commodity or asset that any person is obliged to redeem
            or purchase;</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="12"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >is not for speculative investment;</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="13"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >is not intended to constitute securities in Singapore or any
            relevant jurisdiction;</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="14"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >does not result in any mutual covenants, or agreement to rights and
            obligations, being entered into between you and other holders of
            $FOREX&nbsp;</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: italic;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >inter se</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >; and</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="15"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >is subject to limitations and conditions in these Terms and all
            applicable policies as may be published from time to time on the
            Handle.fi protocol.</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >$FOREX does not have any tangible or physical manifestation, and does
        not have any intrinsic value (nor does any Group Entity or any other
        person make any representation or give any commitment as to its
        value).</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >You acknowledge and agree that no Group Entity is under any obligation
        to issue replacement $FOREX in the event any $FOREX or private key is
        lost, stolen, malfunctioning, destroyed or otherwise inaccessible or
        unusable for any reason.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >IN PARTICULAR, PLEASE NOTE THAT THE SELLER IS IN THE PROCESS OF
        UNDERTAKING LEGAL AND REGULATORY ANALYSIS OF THE FUNCTIONALITY OF
        $FOREX. FOLLOWING THE CONCLUSION OF THIS ANALYSIS, THERE MAY BE CHANGES
        TO THE INTENDED FUNCTIONALITY OF $FOREX IN ORDER TO ENSURE COMPLIANCE
        WITH ANY LEGAL OR REGULATORY REQUIREMENTS TO WHICH THE SELLER OR $FOREX
        IS SUBJECT. IN THE EVENT OF ANY CHANGES TO THE INTENDED FUNCTIONALITY OF
        $FOREX, THE DETAILS OF THE CHANGES SHALL BE PUBLISHED ON THE WEBSITE. IT
        IS YOUR RESPONSIBILITY TO REGULARLY CHECK THE WEBSITE FOR ANY SUCH
        NOTICES.</span
      >
    </p>
    <ol
      start="2"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >SCOPE OF TERMS</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Unless otherwise stated herein, your purchase of $FOREX (whether
        through an intermediary or otherwise), and continued holding and/or
        usage of $FOREX is governed solely by these Terms. New terms or policies
        may be published from time to time on the Handle.fi protocol at our sole
        discretion.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The sale of $FOREX does not constitute the provision of any goods
        and/or services as at the date that these Terms form a binding agreement
        between the Parties.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The Seller shall procure the development of the Handle.fi protocol in
        accordance with the roadmap as described in the Whitepaper, and shall
        procure that the Handle.fi protocol remains operational for a period of
        at least 3 years from the date hereof.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Any potential future usage of $FOREX in connection with providing or
        receiving services or the usage of the Handle.fi protocol (when the same
        is completed and deployed) will be governed primarily by other
        applicable terms and policies (collectively, the&nbsp;</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Service Terms and Policies</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >), which will be made available on the Handle.fi protocol and/or
        Website, if the services and the Handle.fi protocol is successfully
        completed and deployed. The Seller may update these Terms or the Service
        Terms and Policies in our sole and absolute discretion. It shall be your
        responsibility to regularly check the Handle.fi protocol / Website for
        any such notices.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >To the extent of any conflict with these Terms, the updated Terms and
        the Service Terms and Policies which may be published from time to time
        on the Handle.fi protocol shall prevail with respect to any issues
        relating to the usage of $FOREX in connection with the Handle.fi
        protocol.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The Seller reserves the right to require you to provide us with your
        personal details (including without limitation full legal name, address
        and details of the digital wallet from which you have sent the payment),
        and it is your responsibility to provide correct details. Failure to
        provide this information will prevent us from transferring $FOREX to
        your digital wallet.</span
      >
    </p>
    <ol
      start="3"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >CANCELLATION AND REFUSAL AT SELLER&apos;S DISCRETION</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Your purchase of $FOREX (whether through an intermediary or otherwise)
        from the Seller is final; there are no refunds or cancellations except
        as may be required by applicable law or regulation and you waive any
        rights to be refunded any amounts which you have paid to the Seller in
        exchange for $FOREX or to cancel any purchase.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Notwithstanding the foregoing, the Seller reserves the right to refuse
        or cancel any request(s) to purchase or purchases of $FOREX (as the case
        may be), at any time in its sole discretion without giving reasons,
        including without limitation the following:</span
      >
    </p>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px">
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >in connection with any failure to complete know-your-customer,
            anti-money laundering and counter terrorist financing checks
            prescribed by the Seller;</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="2"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >in connection with a change of business or development plan of the
            Seller; or</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="3"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >in connection with an adverse change of the regulatory
            environment.</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >For the avoidance of doubt, the Seller shall not be required to notify
        you of the outcome of any of our customer identification, due diligence
        and/or anti-money laundering due diligence checks, or in any case
        provide reasons for unsatisfactory results of checks. In the event that
        the Seller refuses or cancels any request(s) to purchase $FOREX, the
        price paid by you shall be rejected or refunded (as applicable) in
        accordance with the Seller&apos;s internal policies and procedures, less
        (i) amounts required to be confiscated by applicable laws, (ii) fees and
        expenses incurred in connection with the marketing and/or development of
        the Handle.fi protocol, (iii) blockchain network fees and the
        Seller&apos;s administrative fees for processing such transfer (not
        exceeding 5% of the refund amount), and (iv) the same proportion of
        purchased $FOREX already delivered to the Buyer. The Seller reserves the
        right to require you to provide the Seller with your personal details
        (including without limitation correct name, address and details of the
        digital wallet from which you have sent the payment), and it is your
        responsibility to provide correct details. Failure to provide this
        information will prevent the Seller from allocating the token to your
        digital wallet. No interest will accrue on the value of any
        refund.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >At any time during the sale of $FOREX, the Seller may either
        temporarily suspend or permanently abort the token sale (whether
        relating to the private sale or public sale phase, or both) at its sole
        discretion without providing any reasons whatsoever. During any period
        of suspension or in the event that the token sale is aborted (whether
        relating to the private sale or public sale phase, or both), $FOREX will
        not be available for purchase.</span
      >
    </p>
    <ol
      start="4"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >ACKNOWLEDGMENT AND ASSUMPTION OF RISKS</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        ><span class="Apple-tab-span" style="white-space: pre"
          >&nbsp; &nbsp;&nbsp;</span
        ></span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >You acknowledge and agree that there are numerous risks associated with
        purchasing $FOREX, holding $FOREX, and using $FOREX for participation in
        the Handle.fi protocol. If you have any queries or require any
        clarification regarding these risks, please contact us at
        handle.finance@gmail.com.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >YOU CLEARLY UNDERSTAND THAT BLOCKCHAIN AND VIRTUAL CURRENCIES / TOKENS,
        INCLUDING WITHOUT LIMITATION BTC, ETH, USDT OR USDC ARE NEW AND
        UNVERIFIED TECHNOLOGIES THAT ARE BEYOND CONTROL OF ANY GROUP ENTITY. IN
        PARTICULAR, AND IN ADDITION TO TERMS OF THIS DOCUMENT, YOU BEAR FULL
        RESPONSIBILITY FOR ANY RISKS DESIGNATED IN THE PROPOSED DOCUMENTATION.
        BY PURCHASING (WHETHER THROUGH AN INTERMEDIARY OR OTHERWISE), HOLDING
        AND/OR USING $FOREX, YOU EXPRESSLY ACKNOWLEDGE AND ASSUME THE FOLLOWING
        RISKS:</span
      >
    </p>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px">
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Risk of changes in functionality</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.45pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >$FOREX does not have any rights, uses, purpose, attributes,
        functionalities or features, express or implied, except for those which
        are specifically described in the White Paper and which may change from
        time to time.</span
      >
    </p>
    <ol
      start="2"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >$FOREX is non-refundable</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.45pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The Seller is not obliged to provide $FOREX holders with a refund
        related to $FOREX for any reason, and $FOREX holders acknowledge and
        agree that they will not receive money or other compensation in lieu of
        a refund. No promises of future performance or price are or will be made
        in respect to $FOREX, including no promise of inherent value, no promise
        of continuing payments, and no guarantee that $FOREX will hold any
        particular value. Therefore, the recovery of spent resources may be
        impossible or may be subject to foreign laws or regulations, which may
        not be the same as the laws in the jurisdiction of $FOREX.</span
      >
    </p>
    <ol
      start="3"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Uncertain Regulations and Enforcement Actions</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.45pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The regulatory status of $FOREX and distributed ledger technology is
        unclear or unsettled in many jurisdictions, but numerous regulatory
        authorities across jurisdictions have been outspoken about considering
        the implementation of regulatory regimes which govern virtual currencies
        or virtual currency markets. It is impossible to predict how, when or
        whether regulatory agencies may apply existing regulations or create new
        regulations with respect to such technology and its applications,
        including $FOREX and/or the Handle.fi protocol. Regulatory actions could
        negatively impact $FOREX and/or the Handle.fi protocol in various ways.
        The Seller or any Group Entity may cease operations in a jurisdiction in
        the event that regulatory actions, or changes to law or regulation, make
        it illegal to operate in such jurisdiction, or commercially undesirable
        to obtain the necessary regulatory approval(s) to operate in such
        jurisdiction.</span
      >
    </p>
    <ol
      start="4"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Risks associated with the Blockchain Protocol</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.45pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Given that $FOREX and the Handle.fi protocol are based on blockchain
        protocol and architecture, any malfunction, breakdown or abandonment of
        the relevant blockchain protocol or architecture may have a material
        adverse effect on $FOREX and/or the Handle.fi protocol. Moreover,
        advances in cryptography, or technical advances (including without
        limitation development of quantum computing), could present unknown
        risks to $FOREX and/or the Handle.fi protocol by rendering ineffective
        the cryptographic consensus mechanism that underpins that blockchain
        protocol. The future of cryptography and security innovations are highly
        unpredictable.</span
      >
    </p>
    <ol
      start="5"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Security</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.45pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >You are responsible for implementing reasonable measures for securing
        the digital wallet, vault or other storage mechanism you use to receive
        and hold $FOREX which you have purchased, including any requisite
        passwords, tokens, private key(s) or other credentials necessary to
        access such storage mechanism(s). If your passwords, tokens, private
        key(s) or other access credentials are lost, you may lose access to your
        $FOREX. The Seller cannot be responsible for, and is technologically
        unable to recover, any such losses.</span
      >
    </p>
    <ol
      start="6"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Insufficient Information</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.45pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The Handle.fi protocol is at the stage of development as of the date of
        these Terms and its algorithm, code, consensus mechanism and/or various
        other technical specifications and parameters could be updated and
        changed frequently and constantly. While the marketing materials and
        Whitepaper released relating to the development of the Handle.fi
        protocol has been prepared with the then up-to-date key information of
        the Handle.fi protocol, it is not absolutely complete and is subject to
        adjustments and updates from time to time for optimal development and
        growth of the Handle.fi protocol and/or ecosystem on the Handle.fi
        protocol. The Seller is neither able, nor obliged, to keep you closely
        posted on every detail of the development of the Handle.fi protocol
        (including its progress and expected milestones no matter whether
        rescheduled or not) and therefore will not necessarily provide you with
        timely and full access to all the information relating to the Handle.fi
        protocol that may emerge from time to time. Due to the nature of the
        project to develop the Handle.fi protocol, you accept that such
        insufficiency of information disclosure is inevitable and
        reasonable.</span
      >
    </p>
    <ol
      start="7"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Security weaknesses.</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.7pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Hackers or other malicious groups or organisations may attempt to
        interfere with $FOREX and/or the Handle.fi protocol in a variety of
        ways, including, but not limited to, malware attacks, denial of service
        attacks, consensus-based attacks, Sybil attacks, smurfing and spoofing.
        Furthermore, there is a risk that a third party or a member of any Group
        Entity may intentionally or unintentionally introduce weaknesses into
        the core infrastructure of $FOREX and/or the Handle.fi protocol, which
        could negatively affect $FOREX and/or the Handle.fi protocol.</span
      >
    </p>
    <ol
      start="8"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Risks associated with markets for $FOREX</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.7pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >There is no prior market for $FOREX and the $FOREX sale may not result
        in an active or liquid market for $FOREX. $FOREX is intended to be used
        solely within the network for the Handle.fi protocol, hence there may be
        illiquidity risk with respect to any $FOREX you hold.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.7pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >$FOREX is not a currency issued by any central bank or national,
        supra-national or quasi-national organisation, nor is it backed by any
        hard assets or other credit nor is it a &quot;commodity&quot; in the
        usual and traditional sense of that word. The Seller cannot be
        responsible for, nor does the Seller pursue, the circulation and trading
        of $FOREX on any market. Trading of $FOREX will merely depend on the
        consensus on its value between the relevant market participants. No one
        is obliged to purchase any $FOREX from any holder of $FOREX, including
        the purchasers, nor does anyone guarantee the liquidity or market price
        of $FOREX to any extent at any time. Furthermore, $FOREX may not be
        resold to a purchaser who is a citizen, national, resident (tax or
        otherwise), domiciliary or green card holder of a Restricted Country or
        to purchasers where the purchase of $FOREX may be in violation of
        applicable laws. Accordingly, the Seller cannot ensure that there will
        be any demand or market for $FOREX, or that the price you pay for $FOREX
        is indicative of any market valuation or market price for $FOREX.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.7pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Any secondary market or exchange for trading $FOREX would be run and
        operated wholly independently of the Group Entities, the sale of $FOREX
        and the Handle.fi protocol. No Group Entity will create such secondary
        markets nor will it act as an exchange for $FOREX. Even if secondary
        trading of $FOREX is facilitated by third party exchanges, such
        exchanges may be relatively new and subject to little or no regulatory
        oversight, making them more susceptible to fraud or manipulation.
        Furthermore, to the extent that third parties do ascribe an external
        exchange value to $FOREX (e.g., as denominated in a virtual or fiat
        currency), such value may be extremely volatile, decline below the price
        which you have paid for $FOREX, and/or diminish to zero.</span
      >
    </p>
    <ol
      start="9"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Risk of Uninsured Losses</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.7pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >$FOREX is uninsured unless you specifically obtain private insurance to
        insure them. In the event of loss or loss of utility value, there is no
        public insurer or private insurance arranged by us, to offer recourse to
        you.</span
      >
    </p>
    <ol
      start="10"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Taxation risks</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.7pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The tax characterisation of $FOREX is uncertain. The tax
        characterisation of $FOREX and these Terms is uncertain. The Seller
        intends to treat $FOREX and these Terms neither as an equity interest
        nor as a debt interest in the Seller for tax purposes. It is possible
        that the Seller&rsquo;s intended treatment of $FOREX and these Terms may
        be challenged, so that the tax consequences to a purchaser and the
        Seller relating to $FOREX and these Terms could differ from those
        described above. You must seek your own tax advice in connection with
        the purchase, holding and/or usage of $FOREX, which may result in
        adverse tax consequences to you, including withholding taxes, income
        taxes and tax reporting requirements.</span
      >
    </p>
    <ol
      start="11"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Competitors</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.7pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >It is possible that alternative networks could be established that
        utilise the same or similar code and protocol underlying $FOREX and/or
        the Handle.fi protocol and attempt to re-create similar facilities. The
        Handle.fi protocol may be required to compete with these alternative
        networks, which could negatively impact $FOREX and/or the Handle.fi
        protocol.</span
      >
    </p>
    <ol
      start="12"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Insufficient Interest</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.7pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >It is possible that the Handle.fi protocol will not be used by a large
        number of individuals, companies and other entities or that there will
        be limited public interest in the creation and development of
        distributed ecosystems (such as the Handle.fi protocol). Such a lack of
        use or interest could negatively impact the development of the Handle.fi
        protocol and therefore the potential utility of $FOREX.</span
      >
    </p>
    <ol
      start="13"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Risk of Dissolution of the Seller, any Group Entity or the
            Handle.fi protocol</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.7pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Start-up companies such as the Seller involve a high degree of risk.
        Financial and operating risks confronting start-up companies are
        significant, and the Seller is not immune to these. Start-up companies
        often experience unexpected problems in the areas of product
        development, marketing, financing, and general management, among others,
        which frequently cannot be solved.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.7pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >It is possible that, due to any number of reasons, including, but not
        limited to, an unfavourable fluctuation in the value of virtual and/or
        fiat currencies, decrease in the utility of $FOREX due to negative
        adoption of the Handle.fi protocol, the failure of commercial
        relationships, or intellectual property ownership challenges, the
        Handle.fi protocol may no longer be viable to operate and the Seller or
        any Group Entity may be dissolved.</span
      >
    </p>
    <ol
      start="14"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Risks Arising from Lack of Governance Rights</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.7pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Because $FOREX confers no governance rights of any kind with respect to
        the Handle.fi protocol or any Group Entity, all decisions involving the
        Handle.fi protocol or any Group Entity will be made by the relevant
        Group Entity at its sole and absolute discretion, including, but not
        limited to, decisions to discontinue the services and/or ecosystem on
        the Handle.fi protocol, to create and sell more $FOREX for use in the
        ecosystem on the Handle.fi protocol, or to sell or liquidate any Group
        Entity. These decisions could adversely affect the Handle.fi protocol
        and $FOREX you hold.</span
      >
    </p>
    <ol
      start="15"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Loss of Talent</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.7pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The development of the Handle.fi protocol depends on the continued
        co-operation of the existing technical team and expert consultants, who
        are highly knowledgeable and experienced in their respective sectors.
        The loss of any member may adversely affect the Handle.fi protocol or
        its future development. Further, stability and cohesion within the team
        is critical to the overall development of the Handle.fi protocol. There
        is the possibility that conflict within the team and/or departure of
        core personnel may occur, resulting in negative influence on the project
        in the future.</span
      >
    </p>
    <ol
      start="16"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Failure to develop&nbsp;</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.7pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >There is the risk that the development of the Handle.fi protocol will
        not be executed or implemented as planned, for a variety of reasons,
        including without limitation the event of a decline in the prices of any
        digital asset, virtual currency or $FOREX, unforeseen technical
        difficulties, and shortage of development funds for activities.</span
      >
    </p>
    <ol
      start="17"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Other risks</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 35.7pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >In addition to the aforementioned risks, there are other risks
        associated with your purchase, holding and usage of $FOREX, including
        those that the Seller cannot anticipate. Such risks may further
        materialise as unanticipated variations or combinations of the
        aforementioned risks.</span
      >
    </p>
    <ol
      start="5"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >KNOW YOUR CLIENT REGULATIONS AND PERSONAL DATA&nbsp;</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: underline;
          -webkit-text-decoration-skip: none;
          text-decoration-skip-ink: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Know your client regulations</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >You hereby acknowledge and accept that:</span
      >
    </p>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px">
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >The Seller may be required to conduct customer identification, due
            diligence and anti-money laundering due diligence on all purchasers
            of $FOREX in compliance with all applicable laws and legislations.
            The Seller may determine, in its sole discretion, that it is
            necessary to obtain certain information about you in order to comply
            with these laws and legislations. You agree to provide such
            information to the Seller promptly upon request, and you acknowledge
            that the Seller may refuse to sell $FOREX to you until you provide
            such requested information and the Seller has determined that it is
            permissible to sell you $FOREX under applicable law or
            regulation.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="2"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >The Seller may at any point in time request information and/or
            documentation to establish that its identification records, as well
            as the information that form your profile, remain completely
            updated. In this respect, the Seller reserves the right to examine
            and check on a regular basis the validity and adequacy of your
            identification data and information maintained. For the avoidance of
            doubt, the Seller shall not be required to notify you of the outcome
            of any of its customer identification, due diligence and/or
            anti-money laundering due diligence checks, or in any case provide
            reasons for unsatisfactory results of checks.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="3"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >If at any time the Seller becomes aware that reliable or adequate
            data and information are missing from your identity, the Seller
            reserves the right to take all necessary actions to collect the
            missing data and information (whether from you or from third
            parties) so as to update and complete your profile as
            necessary.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="4"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >If you fail or refuse to submit, within a reasonable timeframe, the
            required data and identification information for the updating of
            your identity and, as a consequence, the Seller is unable to comply
            with any laws, legislations regulations or directives relating to
            customer identification requirements, the Seller will not be able to
            sell $FOREX and/or continue its relationship with you, and the
            Seller may be required to submit a report of suspicious
            transactions/activities to the relevant authorities.</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: underline;
          -webkit-text-decoration-skip: none;
          text-decoration-skip-ink: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Personal Data</span
      >
    </p>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px">
      <li
        aria-level="1"
        style="
          list-style-type: lower-roman;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: 3.552713678800501e-15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >The Seller (and its affiliates) will collect, use, process and
            disclose your information and personal data for providing its
            services and discharging of its legal duties and responsibilities,
            administration, customer services, crime (including tax evasion)
            prevention and detection, anti-money laundering, due diligence and
            verification of identity purposes (collectively, the&nbsp;</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Purposes</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >). &nbsp;The Seller may disclose your information to its service
            providers, agents, relevant custodians or similar third parties for
            these Purposes. &nbsp;The Seller may keep your information for such
            period as it may determine (which shall be no shorter than any
            mandatory period prescribed by law) to contact you about the
            Handle.fi protocol. You hereby consent to the Seller transferring
            your personal data to its affiliates or service providers for
            processing, and to recipients in countries which do not provide the
            same level of data protection as your jurisdiction if necessary for
            the Purposes.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="2"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-roman;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: 3.552713678800501e-15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >If you withdraw your consent to any or all use of your personal
            data, depending on the nature of your request, this may limit the
            scope of our services which the Seller is able to provide to you.
            Please contact us at handle.finance@gmail.com (marking your email
            for the attention of &ldquo;Data Protection Officer&rdquo;).
            &nbsp;The Seller will endeavour to respond to your query / request
            within 30 days, and if that is not possible, the Seller will inform
            you of the time by which it will respond to you.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="3"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-roman;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: 3.552713678800501e-15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You hereby warrant, represent and confirm to us and shall procure
            that with respect to any personal data of any individual (including,
            where applicable, your directors, partners, office holders,
            officers, employees, agents, shareholders and beneficial owners)
            (each, an&nbsp;</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Individual</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >) disclosed to us in connection with these Terms, the Service Terms
            and Policies and/or the $FOREX sale or otherwise collected by us in
            the course of your relationship with us or any of our
            affiliates:</span
          >
        </p>
      </li>
    </ol>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px">
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: 17.450000000000003pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >each Individual to whom the personal data relates has, prior to
            such disclosure or collection, agreed and consented to, and
            permitted you on its behalf to consent to, such disclosure as well
            as the collection, processing, use and disclosure of the
            Individual&rsquo;s personal data by us for all purposes required by
            us in connection with these Terms and/or the $FOREX sale;</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="2"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: 17.450000000000003pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >that each Individual has read and consented to the collection,
            processing, use and disclosure of the Individual&rsquo;s personal
            data by us in accordance with the Purpose; and</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="3"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: 17.450000000000003pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >any consent given pursuant to these Terms in relation to each
            Individual&rsquo;s personal data shall survive death, incapacity,
            bankruptcy or insolvency of that Individual and the termination or
            expiration of these Terms and the Service Terms and Policies.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="4"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-roman;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: 3.552713678800501e-15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >If any Individual should withdraw his/her consent to any or all use
            of his/her personal data, then depending on the nature of the
            withdrawal request, the Seller may not be in a position to continue
            its relationship with you and/or sell $FOREX, and the Seller shall
            be entitled to its rights under these Terms and the Service Terms
            and Policies (without prejudice to our other rights and remedies at
            law against you).</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="6"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >TAXES</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The price that you pay for $FOREX is&nbsp;</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: underline;
          -webkit-text-decoration-skip: none;
          text-decoration-skip-ink: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >exclusive</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >&nbsp;of all applicable taxes (including without limitation obligations
        to pay value added, sales, use, offerings, withholding taxes, income or
        similar taxes) (</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Taxes</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >). The onus for determining the Taxes applicable to your purchase,
        holding and/or usage of $FOREX lies solely with you. It is also your
        sole responsibility to comply with all relevant tax reporting
        requirements arising out of or in connection with your purchase, holding
        and/or usage of $FOREX. The Seller is not responsible for withholding,
        collecting, reporting, or remitting any Taxes arising from your
        purchase, holding and/or usage of $FOREX. The Seller cannot and does not
        provide any tax advice and it recommends that you seek appropriate
        professional advice in this area if required.</span
      >
    </p>
    <ol
      start="7"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >REPRESENTATIONS AND WARRANTIES&nbsp;</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >By purchasing (whether through an intermediary or otherwise), holding
        and/or using $FOREX, you represent and warrant that:</span
      >
    </p>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px">
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You have read and understand these Terms and the Whitepaper, and
            you have all requisite power and authority to execute and deliver
            these Terms, to participate in the $FOREX sale, to purchase, hold
            and/or use $FOREX, and to carry out and perform your obligations
            under these terms.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="2"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >If you are an individual, you are at least 21 years old and in any
            case of sufficient legal age and capacity to purchase, hold and/or
            use $FOREX. If you are a legal person, you are duly organised,
            validly existing and in good standing under the laws of your
            domicile and each jurisdiction where you conduct business or where
            your assets are located. You are not purchasing, holding and/or
            using $FOREX on behalf of any other entity or person.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="3"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >The execution, delivery and performance of these Terms will not
            result in any violation of, be in conflict with, or constitute a
            default under, with or without the passage of time or the giving of
            notice: (i) any provision of your constitutional documents (if
            applicable), (ii) any provision of any judgment, decree or order, or
            any agreement, obligation, duty or commitment to which you are a
            party, or by which you are bound, or to which any of its material
            assets are subject, (iii) any laws, regulations or rules applicable
            to you, (iv) any foreign exchange or regulatory restrictions
            applicable to such purchase, holding and/or usage of $FOREX, or (v)
            any governmental or other consents that may need to be
            obtained.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="4"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >The execution and delivery of, and performance under, these Terms
            require no approval or other action from any governmental authority
            or person. You will and shall at your own expense ensure compliance
            with all laws, regulatory requirements and restrictions applicable
            to you (as the case may be).</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="5"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Your purchase of $FOREX shall be made in full compliance with any
            applicable tax obligations to which you may be subject in any
            relevant jurisdiction. You understand that you bear the sole
            responsibility to determine if your use of the Handle.fi protocol,
            the transfer of any virtual currency to the Seller, the creation,
            ownership or use of $FOREX, the potential appreciation or
            depreciation in the value of $FOREX over time, the sale and purchase
            of $FOREX and/or any other action or transaction related to the
            Seller or the Handle.fi protocol have tax implications (including
            determining what taxes may apply to the acquisition, possession,
            storage, sale or other use of $FOREX including, for example, sales,
            use, value-added and similar taxes &nbsp;and for complying with any
            obligations to withhold, collect, report and remit the correct taxes
            to the appropriate tax authorities in relation to its acquisition,
            possession, storage, sale or other use of $FOREX); by creating,
            holding or using $FOREX, and to the extent permitted by law, you
            agree not to hold any third party (including developers, auditors,
            contractors or founders) liable for any tax liability associated
            with or arising from the creation, ownership or use of $FOREX or any
            other action or transaction related to the Seller (or any Group
            Entity) or the Handle.fi protocol.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="6"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You have good and sufficient understanding in business and
            financial matters, including a good and sufficient understanding of
            the functionality, usage, storage, transmission mechanisms and other
            material characteristics of blockchain technology, blockchain-like
            technology, blockchain-based software systems</span
          ><span
            style="
              font-size: 10pt;
              font-family: Arial;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&nbsp;</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >as well as other similar technologies and systems, cryptographic
            tokens, and token storage mechanisms (such as digital token wallets)
            to understand these Terms and to appreciate the risks and
            implications of purchasing, holding and/or usage of $FOREX.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="7"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You have obtained sufficient information about $FOREX to make an
            informed decision to purchase, hold and/or use $FOREX.</span
          ><span
            style="
              font-size: 10pt;
              font-family: Arial;
              color: #000000;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&nbsp;</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Additionally, you are aware of the Seller&rsquo;s business affairs
            and financial condition and have obtained sufficient information
            about the Seller to reach an informed decision to purchase
            $FOREX.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="8"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >The currency (including any fiat, digital currency, virtual
            currency or cryptocurrency) used to purchase $FOREX are obtained
            through &ldquo;mining&rdquo; activities or other lawful means, and
            are not derived from or related to any unlawful activities,
            including but not limited to money laundering or terrorist financing
            and all applicable statutes of all jurisdictions in which you are
            located, resident, organised or operating, and/or to which it may
            otherwise be subject and the rules and regulations thereunder
            (collectively, the&nbsp;</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Compliance Regulations</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >), and you will not use $FOREX to finance, engage in, or otherwise
            support any unlawful activities or in a manner which aids or
            facilitates another party in the same. To the extent required by
            applicable laws and regulations, you shall fully comply with all
            Compliance Regulations and no action, suit or proceeding by or
            before any court or governmental agency, authority or body or any
            arbitrator involving it or any of your affiliates with respect to
            the Compliance Regulations is pending or, to the best of your
            knowledge (after due and careful enquiry), threatened.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="9"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        class="hfi-danger"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Neither you (nor any of your subsidiaries, any director or officer,
            or any employee, agent, or your affiliate) nor any person having a
            direct or indirect beneficial interest in you or $FOREX being
            acquired by you, or any person for whom you are acting as agent or
            nominee in connection with $FOREX: (i) is the subject of any
            sanctions administered or enforced by any country or government or
            international authority, including the US Department of the
            Treasury&rsquo;s Office of Foreign Assets Control (&quot;</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >OFAC</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&quot;), the US Department of State, the United Nations Security
            Council, the European Union, Her Majesty&apos;s Treasury, the Hong
            Kong Monetary Authority or the Monetary Authority of Singapore
            (collectively, &quot;</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Sanctions</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >&quot;); (ii) is located, organised, citizen or resident in a
            country or territory that is, or whose government is, the subject of
            Sanctions (including without limitation Cuba, North Korea,
            Timor-Leste, Cambodia, Republic of the Union of Myanmar, Laos,
            Tanzania, Russia, Serbia, Tunisia, Uganda, Mali, Afghanistan,
            Albania, Central African Republic, Republic of C&ocirc;te
            d&apos;Ivoire, Somalia, Zimbabwe, Democratic Republic of the Congo,
            Republic of the Congo, C&ocirc;te d&rsquo;Ivoire, Malawi,
            Montenegro, Mozambique, Crimea, Kyrgyzstan, Bosnia and Herzegovina,
            Uzbekistan, Turkmenistan, Burundi, South Sudan, Sudan (north), Sudan
            (Darfur), the Republic of Guinea, Guinea-Bissau, the Republic of
            North Macedonia, the Lebanese Republic, Bahamas, Kosovo, Turkey,
            Iran, Iraq, Libya, Syria, Ethiopia, Yemen, Sri Lanka, Ukraine,
            Belarus, Bolivia, Trinidad and Tobago, and Venezuela); (iii) is
            listed in any list of sanctioned persons including those maintained
            under the Sanctions including the List of Specially Designated
            Nationals and Blocked Persons or the Foreign Sanctions Evaders List
            maintained by OFAC; or (iv) is directly or indirectly owned or
            controlled by any person subject to sub-clauses (i), (ii) and (iii)
            above.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="10"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        class="hfi-danger"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Neither you (nor any of your subsidiaries, any director or officer,
            or any employee, agent, or your affiliate) nor any person having a
            direct or indirect beneficial interest in you or $FOREX being
            acquired by you, or any person for whom you are acting as agent or
            nominee in connection with $FOREX is: (i) a citizen or resident of,
            or located in, a geographic area or country designated as
            &quot;High-risk and other monitored jurisdictions&quot; (or such
            other similar classification) by the Financial Action Task Force; or
            (ii) a Politically Exposed Person (defined as a current or former
            senior official in the executive, legislative, administrative,
            military, or judicial branch of a government (elected or not), a
            senior official of a major political party, a senior executive of a
            government owned commercial enterprise, and/or being a corporation,
            business or other entity formed by or for the benefit of any such
            individual, any individual publicly known (or actually known) to be
            a close personal or professional associate, or an immediate family
            member of such individual, meaning spouse, parents, siblings,
            children, and spouse&rsquo;s parents or siblings).</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="11"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        class="hfi-danger"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You are not (i) a citizen, national, resident (tax or otherwise),
            domiciliary or green card holder of a geographic area or country (A)
            where participation in token sales is prohibited, restricted or
            unauthorised by applicable law, decree, regulation, treaty, or
            administrative act or (B) where it is likely that the sale of $FOREX
            would be construed as the sale of a security (howsoever named),
            financial service or investment product (including without
            limitation the United States of America and the People&apos;s
            Republic of China (the&nbsp;</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Restricted Countries</span
          ><span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >).</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="12"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        class="hfi-danger"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You acknowledge and agree that: (i) you are familiar with all
            related regulations in the specific jurisdiction in which you are
            based and that acquiring $FOREX (through purchase or otherwise) in
            that jurisdiction is not prohibited, restricted or subject to
            additional conditions of any kind, (ii) no regulatory authority has
            examined or approved of the information set out in the Whitepaper or
            any other material in connection with $FOREX, (iii) you will not use
            $FOREX if such use would constitute a public offering of $FOREX in
            any country or jurisdiction where action for that purpose is
            required, (iv) the distribution or dissemination of the Whitepaper,
            any part thereof or any copy thereof, or any use of $FOREX by you,
            is not prohibited or restricted by the applicable laws, regulations,
            or rules in your jurisdiction, and where any restrictions in
            relation to possession are applicable, you will observe and comply
            with all such restrictions at your own expense and risk without
            liability to the Seller, (v) you shall ensure that no obligations
            are imposed on the Seller in any such jurisdiction as a result of
            any of the actions taken by you in the preceding sub-clause, and
            (vi) the Seller will have no responsibility for and it will not
            obtain any consent, approval or permission required by you for, the
            acquisition, offer, sale or delivery by it of $FOREX under the laws
            and regulations in force in any jurisdiction to which you may be
            subject or in or from which you use $FOREX.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="13"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        class="hfi-danger"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >The currency (whether fiat or virtual) used in the purchase of
            $FOREX will be made only in your name, from a digital wallet not
            located in a country or territory that has been designated as a
            &ldquo;non-cooperative country or territory&rdquo; by the Financial
            Action Task Force or any similar legislation.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="14"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You are purchasing, holding and/or using $FOREX to participate in
            the Handle.fi protocol, as well as to support the advancement,
            promotion, research, design and development of, and advocacy for the
            Handle.fi protocol, as well as potentially receiving services on the
            Handle.fi protocol (when the same is completed and deployed). You
            are not purchasing, holding or using $FOREX for any other uses or
            purposes, including, but not limited to, any investment, speculative
            or other financial purposes.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="15"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You acknowledge that: (i) $FOREX does not have any intrinsic value
            and that it may never recover any cash, cryptocurrency or other
            assets which are used directly or indirectly to acquire $FOREX; (ii)
            there is no market-standard valuation process to determine the value
            of $FOREX at any given time; and (iii) the Seller gives no
            guarantees whatsoever on the value of $FOREX which may be highly
            volatile and could reduce to zero.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="16"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You acknowledge and agree that the Seller may impose eligibility
            criteria to access certain functionality in respect of $FOREX which
            may require it to incur additional time and money costs.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="17"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You shall not sell or transfer or agree to sell to transfer
            (whether pursuant to any public pool or private agreement with a
            subsequent purchaser or otherwise) any $FOREX prior to the
            completion of the public sale of $FOREX.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="18"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You shall not sell or transfer any $FOREX prior to procuring the
            purchaser&apos;s or transferee&apos;s agreement to these
            Terms.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="19"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You acknowledge that the currency (whether fiat or virtual) paid to
            us for the purchase of $FOREX will be held by us (or our affiliate)
            after the token sale, and you will have no economic or legal right
            over or beneficial interest in these contributions or the assets of
            that entity after the token sale.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="20"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You acknowledge and undertake that you shall provide the Seller
            with such information as the Seller may deem necessary or
            appropriate in order to maintain compliance with applicable law
            including: (i) compliance with the representations set out in this
            Clause 7, and (ii) to address any actual inquiries or inquiries that
            the Seller may (at its sole discretion) expect from regulatory
            authorities, courts or arbitral authorities in any
            jurisdiction.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="21"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You hereby acknowledge and agree that the Seller may have to
            procure an amendment to the functionality of $FOREX at any time in
            order to facilitate compliance with any legal or regulatory issues
            which may arise or shall be anticipated, including: (i) any actual
            action taken, or potential action that the Seller (in its sole
            discretion) expects to be taken, by a court or regulatory authority
            in any jurisdiction in relation to the use of $FOREX and all related
            matters, and (ii) any additional legal or regulatory risk mitigation
            in respect of the functionality of $FOREX that the Seller decides to
            undertake at any time.</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >You hereby acknowledge that the Seller has entered into these Terms in
        reliance upon your representations and warranties being true, accurate,
        complete and non-misleading. The Seller does not and does not purport to
        make, and hereby disclaims, all representations, warranties or
        undertaking to you in in relation to the sale of $FOREX or otherwise.
        Prospective purchasers of $FOREX should carefully consider and evaluate
        all risks and uncertainties (including financial and legal risks and
        uncertainties) associated with the $FOREX sale, the Seller, and any
        relevant Group Entity.</span
      >
    </p>
    <ol
      start="8"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >INTELLECTUAL PROPERTY</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The Seller (or the relevant Group Entity, as the case may be) retains
        all right, title and interest in all of that entity&apos;s intellectual
        property, including, without limitation, ideas, concepts, discoveries,
        processes, code, compositions, formulae, methods, techniques,
        information, data, patents, models, rights to inventions, copyright and
        neighbouring and related rights, moral rights, trademarks and service
        marks, business names and domain names, rights in get-up and trade
        dress, goodwill and the right to sue for passing off or unfair
        competition, rights in designs, rights in computer software, database
        rights, rights to use, and protect the confidentiality of, confidential
        information (including know-how and trade secrets), and all other
        intellectual property rights, in each case whether patentable,
        copyrightable or protectable in trademark, registered or unregistered,
        and including all applications and rights to apply for and be granted,
        renewals or extensions of, and rights to claim priority from, such
        rights and all similar or equivalent rights or forms of protection which
        subsist or will subsist now or in the future in any part of the world.
        You may not use any of the Seller&rsquo;s (or the relevant Group
        Entity&apos;s) intellectual property for any reason whatsoever.</span
      >
    </p>
    <ol
      start="9"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >INDEMNITY</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >To the fullest extent permitted by applicable law, you will indemnify,
        defend and hold harmless the Seller, each Group Entity, and their
        respective past, present and future employees, officers, directors,
        contractors, consultants, equity holders, suppliers, vendors, service
        providers, related companies, affiliates, agents, representatives,
        predecessors, successors and assigns (the&nbsp;</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Indemnified Parties</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >) from and against all claims, demands, actions, damages, losses, costs
        and expenses (including legal fees on an indemnity basis) arising from
        or relating to:</span
      >
    </p>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px">
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >your purchase (whether through an intermediary or otherwise),
            holding or usage of $FOREX;</span
          >
        </p>
      </li>
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >your responsibilities or obligations under these Terms;</span
          >
        </p>
      </li>
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >your violation of these Terms;</span
          >
        </p>
      </li>
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >your violation of any rights of any other person or entity;
            or</span
          >
        </p>
      </li>
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >your subsequent transfer of $FOREX to any individuals or
            entities.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="10"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >RELEASE</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >To the fullest extent permitted by applicable law, you release the
        Seller and the other Indemnified Parties from responsibility, liability,
        claims, demands and/or damages (actual and consequential) of every kind
        and nature, known and unknown (including, but not limited to, claims of
        negligence), arising out of or related to disputes between users and the
        acts or omissions of third parties. You expressly waive any rights you
        may have under any statute or common law principles that would otherwise
        limit the coverage of this release to include only those claims which
        you may know or suspect to exist in your favour at the time of agreeing
        to this release.</span
      >
    </p>
    <ol
      start="11"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >GOVERNING LAW AND DISPUTE RESOLUTION</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >These Terms will be governed by and construed and enforced in
        accordance with the laws of Singapore, without regard to conflict of law
        rules or principles (whether of Singapore or any other jurisdiction)
        that would cause the application of the laws of any other jurisdiction.
        Any dispute arising out of or in connection with these Terms or any of
        the transactions contemplated in connection with the same (including
        without limitation the enforceability of this arbitration Clause, any
        question regarding existence, validity or termination) shall be referred
        to and finally resolved by arbitration administered by the Singapore
        International Arbitration Centre (</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >SIAC</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >) in accordance with the Arbitration Rules of the Singapore
        International Arbitration Centre (</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >SIAC Rules</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >) for the time being in force, which rules are deemed to be
        incorporated by reference in this arbitration Clause. The seat of the
        arbitration shall be Singapore. The Tribunal shall consist of 1
        arbitrator. The language of the arbitration shall be English.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Each Party agrees to keep all matters relating to this arbitration,
        including the arbitral awards, confidential, except as is otherwise
        required by court order or as is necessary to confirm, set aside or
        enforce the arbitral award and for disclosure in confidence to each
        Party&rsquo;s respective legal, financial or other professional
        advisors.</span
      >
    </p>
    <ol
      start="12"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >PARTIAL INVALIDITY</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >If, at any time, any provision of these Terms is or becomes illegal,
        invalid or unenforceable in any respect under any law of any
        jurisdiction, neither the legality, validity or enforceability of the
        remaining provisions nor the legality, validity or enforceability of
        such provision under the law of any other jurisdiction will in any way
        be affected or impaired.</span
      >
    </p>
    <ol
      start="13"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >TERMINATION</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The agreement set out in these Terms will terminate upon the completion
        of all sales of $FOREX. The Seller reserves the right to terminate the
        agreement set out in these Terms, in its sole discretion, in the event
        of a breach by you of these Terms. Upon termination of these
        Terms:</span
      >
    </p>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px">
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >all of your rights under these Terms immediately terminate;</span
          >
        </p>
      </li>
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >you are not entitled to any refund of any amount paid whatsoever,
            save in the case where these Terms are terminated by the Seller
            without any breach by you of these Terms; and</span
          >
        </p>
      </li>
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Clauses 3, 4, 6, 9, 10, 17, 18 and 19 will survive and continue to
            apply in accordance with their terms to you in respect of any $FOREX
            held, together with any of your obligation(s), or required
            performance under these Terms which, by its express terms or nature
            and context is intended to survive expiration or termination of
            these Terms.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="14"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >ENTIRE AGREEMENT</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >These Terms, including the documents and material incorporated by
        reference, constitute the entire agreement between you and the Seller
        and supersedes all prior or contemporaneous agreements and
        understandings (including without limitation the Whitepaper, the Website
        or any other marketing material), both written and oral, between you and
        the Seller. The Seller may make changes to these Terms from time to time
        as reasonably required to comply with applicable law or regulation. If
        the Seller makes changes, it will as soon as practicable post the
        amended Terms at the Website. The amended Terms will be effective
        immediately. It is your responsibility to regularly check the Website
        for any such amendments.</span
      >
    </p>
    <ol
      start="15"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >ASSIGNMENT</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >You shall under no circumstances be entitled to assign or novate your
        rights and obligations under these Terms (including without limitation
        the right to claim any $FOREX purchased). T</span
      ><span
        style="
          font-size: 11pt;
          font-family: 'CIDFont+F2';
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >he Seller may assign or novate its rights and obligations under these
        Terms without your consent, and you agree to, at your own
        expense,&nbsp;</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >take whatever action or execute any document which the Seller may
        require for the purpose of effecting any such assignment or novation by
        the Seller</span
      ><span
        style="
          font-size: 11pt;
          font-family: 'CIDFont+F2';
          color: #000000;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >.</span
      >
    </p>
    <ol
      start="16"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >REMEDIES AND WAIVERS</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >No failure to exercise, nor any delay in exercising, on our part, any
        right or remedy under these Terms Documents shall operate as a waiver,
        of any such right or remedy or constitute an election to affirm these
        Terms. No election to affirm these Terms on our part shall be effective
        unless it is in writing. No single or partial exercise of any right or
        remedy prevents any further or other exercise or the exercise of any
        other right or remedy. The rights and remedies provided in these Terms
        are cumulative and not exclusive of any rights or remedies provided by
        law.</span
      >
    </p>
    <ol
      start="17"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >DISCLAIMERS</span
          >
        </p>
      </li>
    </ol>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px">
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >You expressly acknowledge, understand and agree that you are
            purchasing (whether through an intermediary or otherwise), holding
            and/or using $FOREX at your sole risk and discretion, and that
            $FOREX is provided, used and purchased on an &ldquo;AS IS&rdquo; and
            on an &ldquo;AS AVAILABLE&rdquo; basis without any representations,
            warranties, promises or guarantees whatsoever of any kind by the
            Seller or any Group Entity. Prior to making any decision to purchase
            (whether through an intermediary or otherwise), hold and/or use
            $FOREX, you shall conduct your own due diligence and rely only on
            your own examination and investigation thereof.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="2"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >Changes in relevant laws and regulations in any jurisdictions which
            the Seller is operating shall constitute a force majeure and the
            Seller will not be responsible for any result arising out of such
            changes in relevant laws and regulations.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="3"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >The Seller does not make and expressly disclaims all
            representations and warranties, express, implied or statutory; and
            with respect to $FOREX, the Seller specifically does not represent
            and warrant and expressly disclaims any representation or warranty,
            express, implied or statutory, including without limitation, any
            representations or warranties of title, non-infringement,
            merchantability, usage, suitability or fitness for any particular
            purpose, or as to the workmanship or technical coding thereof, or
            the absence of any defects therein, whether latent or patent. In
            addition, the Seller cannot and does not represent or warrant that
            $FOREX or the delivery mechanism for $FOREX are free of security
            vulnerabilities, viruses, errors, &nbsp;failures, bugs or loopholes
            which may be exploited by third parties, or other harmful
            components.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="4"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >The Seller assumes that you have already read these Terms,
            especially the risks and disclaimer stated herein and hereunder, and
            you shall automatically be regarded agree to take all risks
            (including but not limited to the risks stated herein) in relation
            to purchasing (whether through an intermediary or otherwise),
            holding and/or using $FOREX.</span
          >
        </p>
      </li>
    </ol>
    <ol
      start="18"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >SELLER NOT LIABLE</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >$FOREX is not being structured or sold as securities or any other form
        of investment product. Accordingly, none of the information presented in
        these Terms is intended to form the basis for any investment decision,
        and no specific recommendations are intended. Save in the case of fraud
        or gross negligence, the Seller expressly disclaims any and all
        responsibility for any direct, indirect, special, incidental,
        consequential or exemplary loss or damage of any kind whatsoever arising
        directly or indirectly (including without limitation, those relating to
        loss of revenue, income or profits, loss of use or data, or damages for
        business interruption) in connection with:</span
      >
    </p>
    <ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px">
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >reliance on any information contained in these terms;</span
          >
        </p>
      </li>
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >any error, omission or inaccuracy in any such information;</span
          >
        </p>
      </li>
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >any action resulting from such information; or</span
          >
        </p>
      </li>
      <li
        aria-level="1"
        style="
          list-style-type: lower-alpha;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -0.14999999999999858pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 400;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >the sale or usage of $FOREX.</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >In no event will the aggregate liability of the Seller and the
        Indemnified Parties (jointly), whether in contract, warranty, tort, or
        other theory, arising out of or relating to these terms or the usage of
        or inability to use $FOREX, exceed the amount you pay to us for
        $FOREX.</span
      >
    </p>
    <ol
      start="19"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >CLAIMS</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The Seller shall not be liable in any way or in any event in respect of
        any claim under these Terms if such claim was not made within the
        6-month period commencing from the date that you receive $FOREX
        (the&nbsp;</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Claim Period</span
      ><span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >). Any claim which has been made before the expiration of the Claim
        Period shall, if it has not been previously satisfied in full, settled
        or withdrawn, be deemed to have been withdrawn and shall become fully
        barred and unenforceable on the expiry of the period of six (6) months
        commencing from the date on which such claim was made, unless
        proceedings in respect thereof shall have been commenced against the
        Seller and for this purpose proceedings shall not be deemed to have been
        commenced unless they shall have been issued and served upon the
        Seller.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >For the avoidance of doubt, nothing in these Terms shall limit your
        obligation (at law or otherwise) to mitigate your loss in respect of any
        claim under these Terms, and you shall not be entitled to recover
        damages in respect of any claim (as the case may be) if, and to the
        extent that, you have already recovered damages in respect of the same
        fact or subject matter.</span
      >
    </p>
    <ol
      start="20"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >PARTNERSHIP</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Purchasing (whether through an intermediary or otherwise), holding
        and/or using $FOREX does not create any form of partnership, joint
        venture or any other similar relationship between you and us, nor cause
        the Parties to be deemed acting in concert in any respect.</span
      >
    </p>
    <ol
      start="21"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >CONFIDENTIALITY</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >You shall hold, and shall cause your affiliates, officers, directors,
        employees, accountants, counsel, consultants, advisors and agents to
        hold, in confidence all documents, information and correspondence
        concerning $FOREX or any Group Entity furnished to you or your
        affiliates in connection with the transactions contemplated by these
        Terms or any pre-contractual or post-contractual negotiations in
        connection therewith (including without limitation all commercial
        information such as sale price, number of tokens sold, discount (if
        any), as well as schedule for delivery of tokens), except to the extent
        that such information can be shown to have been (a) previously known on
        a non-confidential basis by you, (b) in the public domain disclosed
        without any fault on your part or (c) later lawfully acquired by you
        from sources other than any Group Entity. &nbsp;If these Terms are
        terminated, you shall, and shall cause your affiliates, officers,
        directors, employees, accountants, counsel, consultants, advisors and
        agents to, destroy or deliver to the Seller, upon request, all documents
        and other materials, and all copies thereof, obtained by you or your
        affiliates in connection with these Terms that are subject to such
        confidence.</span
      >
    </p>
    <ol
      start="22"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >RIGHTS OF THIRD PARTIES</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Except as otherwise provided in herein, these Terms are intended solely
        for the benefit of you and us and are not intended to confer third-party
        beneficiary rights upon any other person or entity.&nbsp;</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Save for any Group Entity who shall be entitled to enforce or enjoy the
        benefit of the terms of these Terms, a person who is not a party under
        these Terms has no right under the Contracts (Rights of Third Parties)
        Act, Chapter 53B of Singapore to enforce or to enjoy the benefit of any
        term of these Terms.</span
      >
    </p>
    <ol
      start="23"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >LANGUAGE</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 8pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >You acknowledge that, solely for convenience, these Terms may be
        translated into a language other than English, and that a copy of the
        English language version of these Terms has been provided to you (which
        have read and understand). In the event of conflict or ambiguity between
        the English language version and translated versions of these Terms, the
        English language version shall prevail.</span
      >
    </p>
    <ol
      start="24"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >SEVERABILITY</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >Whenever possible, each provision of these Terms will be interpreted in
        such manner as to be effective and valid under applicable law, but if
        any provision or part-provision of these Terms is or becomes invalid,
        illegal or unenforceable in any respect under any law of any
        jurisdiction, it shall be deemed modified to the minimum extent
        necessary to make it valid, legal and enforceable. &nbsp;If such
        modification is not possible, the relevant provision or part-provision
        shall be deemed deleted. &nbsp;Any modification to or deletion of a
        provision or part-provision pursuant to this Clause 24 shall not affect
        or impair the validity and enforceability of the rest of these Terms,
        nor the validity and enforceability of such provision or part-provision
        under the law of any other jurisdiction.</span
      >
    </p>
    <ol
      start="25"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >INTERPRETATION</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 18pt;
        text-align: justify;
        margin-top: 0pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >The language in these Terms will be interpreted as to its fair meaning,
        and not strictly for or against any party.</span
      >
    </p>
    <ol
      start="26"
      style="margin-top: 0; margin-bottom: 0; padding-inline-start: 54px"
    >
      <li
        aria-level="1"
        style="
          list-style-type: decimal;
          font-size: 11pt;
          background-color: transparent;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          margin-left: -18.15pt;
        "
      >
        <p style="line-height: 1.295; text-align: justify; margin-top: 0pt">
          <span
            style="
              font-size: 11pt;
              background-color: transparent;
              font-weight: 700;
              font-style: normal;
              font-variant: normal;
              text-decoration: none;
              vertical-align: baseline;
              white-space: pre;
              white-space: pre-wrap;
            "
            >NOTICES</span
          >
        </p>
      </li>
    </ol>
    <p
      style="
        line-height: 1.295;
        margin-left: 17.85pt;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 8pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >You agree and acknowledge that all agreements, notices, disclosures,
        and other communications that the Seller provides to you, including
        these Terms, will be provided in electronic form.</span
      >
    </p>
    <p
      style="
        line-height: 1.295;
        text-align: justify;
        margin-top: 0pt;
        margin-bottom: 8pt;
      "
    >
      <span
        style="
          font-size: 11pt;
          background-color: transparent;
          font-weight: 400;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre;
          white-space: pre-wrap;
        "
        >These Terms have been entered into for and on behalf of the Seller. If
        you have any questions regarding these Terms, please contact us at
        handle.finance@gmail.com.</span
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "Tncs",
};
</script>
