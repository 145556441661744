<template>
  <div
    class="uk-modal-dialog uk-animation-slide-top-medium uk-modal-body uk-margin-auto-vertical uk-padding-small"
  >
    <a class="uk-modal-close-default">
      <i class="fal fa-times"></i>
    </a>

    <h3 class="hfi-modal-title uk-margin-small-bottom">
      <i class="fal fa-info-square"></i>
      instructions
    </h3>

    <div
      class="uk-card uk-card-small uk-card-default uk-card-body uk-flex uk-flex-middle hfi-tge-card-1"
    >
      <div class="hfi-card-number">
        <p class="uk-h3 uk-margin-remove-bottom hfi-card-number">1</p>
      </div>
      <p class="uk-margin-remove">connect your wallet.</p>
    </div>

    <div
      class="uk-card uk-card-small uk-card-default uk-card-body uk-flex uk-flex-middle uk-margin-small-top hfi-tge-card-2"
    >
      <div class="hfi-card-number">
        <p class="uk-h3 uk-margin-remove-bottom hfi-card-number">2</p>
      </div>
      <p class="uk-margin-remove">
        carefully read and agree to all the terms &amp; conditions.
      </p>
    </div>

    <div
      class="uk-card uk-card-small uk-card-default uk-card-body uk-flex uk-flex-middle uk-margin-small-top hfi-tge-card-3"
    >
      <div class="hfi-card-number">
        <p class="uk-h3 uk-margin-remove-bottom hfi-card-number">3</p>
      </div>
      <p class="uk-margin-remove">
        metamask window will appear and ask you to "sign" a transaction that you
        agree - sign this transaction in your wallet.
      </p>
    </div>

    <div
      class="uk-card uk-card-small uk-card-default uk-card-body uk-flex uk-flex-middle uk-margin-small-top hfi-tge-card-4"
    >
      <div class="hfi-card-number">
        <p class="uk-h3 uk-margin-remove-bottom hfi-card-number">4</p>
      </div>
      <p class="uk-margin-remove">
        enter the amount of ethereum you wish to contribute to the TGE.
      </p>
    </div>

    <div
      class="uk-card uk-card-small uk-card-default uk-card-body uk-flex uk-flex-middle uk-margin-small-top hfi-tge-card-5"
    >
      <div class="hfi-card-number">
        <p class="uk-h3 uk-margin-remove-bottom hfi-card-number">5</p>
      </div>
      <p class="uk-margin-remove">select contribute.</p>
    </div>

    <div
      class="uk-card uk-card-small uk-card-default uk-card-body uk-flex uk-flex-middle uk-margin-small-top hfi-tge-card-6"
    >
      <div class="hfi-card-number">
        <p class="uk-h3 uk-margin-remove-bottom hfi-card-number">6</p>
      </div>
      <p class="uk-margin-remove">
        confirm this transaction in your metmask wallet.
      </p>
    </div>

    <div
      class="uk-card uk-card-small uk-card-default uk-card-body uk-flex uk-flex-column uk-margin-small-top hfi-tge-card-7"
    >
      <p class="uk-margin-small-bottom">
        once confirmed, that's it! you've now contributed to the handle.fi TGE.
      </p>
      <p class="uk-margin-remove-top uk-margin-small-bottom">
        at the end of the event you will see both your final FOREX price and
        allocation size and can immediately claim any ETH refund if applicable.
      </p>
      <p class="uk-margin-remove-top uk-margin-small-bottom">
        FOREX will be claimable here 27 Sep 4:00 am UTC.
      </p>
      <p class="uk-margin-remove-top">
        checkout the important dates and stay tuned on twitter for further
        announcements.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TgeInstructions",
};
</script>
